import { css } from "@emotion/css";
import { GitBranchIcon, LinearProgress } from "@octopusdeploy/design-system-components";
import type { BranchSpecifier, LicenseStatusResource, ProjectGroupResource, ProjectResource, ValidateGitRefV2Response } from "@octopusdeploy/octopus-server-client";
import { HostingEnvironment, Permission, UseDefaultBranch, ValidateGitRefV2ResponseType } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { Environment } from "@octopusdeploy/utilities";
import type { PropsWithChildren, ReactNode } from "react";
import * as React from "react";
import { BranchSelector } from "~/areas/projects/components/BranchSelector/BranchSelector";
import { ProjectLinksVNext } from "~/areas/projects/components/ProjectLayout/ProjectLinksVNext";
import SampleProjectTour from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
import SampleProjectTourResume from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTourResume";
import { alwaysHideOnboardingQuestionnaireKey } from "~/areas/projects/components/Projects/OnboardingQuestionnaire/AlwaysHideOnboardingQuestionnaire";
import { NewlyCreatedProjectWizardDialog } from "~/areas/projects/components/Projects/OnboardingQuestionnaire/NewlyCreatedProjectWizardDialog";
import { useIsProjectTenantVariablesEnabled } from "~/areas/projects/components/Variables/TenantVariables/useIsProjectTenantVariablesEnabled";
import { VcsErrorPanel } from "~/areas/projects/components/VersionControl/VcsErrorPanel";
import { useIsPageVersionControlled } from "~/areas/projects/context/useIsPageVersionControlled";
import { repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import BusyFromPromise from "~/components/BusyFromPromise/BusyFromPromise";
import { DisabledChip } from "~/components/Chips";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import ErrorPanel from "~/components/ErrorPanel/ErrorPanel";
import { GettingStartedFooter } from "~/components/GettingStarted/GettingStartedFooter";
import { useSetInPageNavVisible } from "~/components/GlobalLayout/InPageNavVisibilityContext";
import { SomethingsWrong } from "~/components/Images/NotFound/SomethingsWrong";
import InternalLink from "~/components/Navigation/InternalLink/index";
import NavigationSidebarLayout from "~/components/NavigationSidebarLayout/index";
import { PageHeaderPrimary } from "~/components/PageHeaderPrimary";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { PortalPageLayout } from "~/components/PortalPageLayout/PortalPageLayout";
import { useIsPageHeaderVNextEnabled } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import { useIsVerticalNavigationEnabled } from "~/components/RootRoutes/useIsVerticalNavigationEnabled";
import { SkeletonLoadingLayout } from "~/components/SkeletonLoadingLayout/SkeletonLoadingLayout";
import type { ProjectContextState } from "../../context";
import { ProjectContextProvider } from "../../context";
import { CreateReleaseButton } from "../Releases";
import { VcsErrorPage } from "../VersionControl/VcsErrorPage";
import { ProjectLinks } from "./ProjectLinks";
import styles from "./style.module.less";
export interface ProjectLayoutProps {
    spaceId: string;
    projectSlug: string;
    branchName: string | undefined;
    isNewlyCreatedProject?: string | null;
    children: ReactNode;
}
export function ProjectLayout({ spaceId, projectSlug, isNewlyCreatedProject, branchName, children }: ProjectLayoutProps) {
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabled();
    const isPageVersionControlled = useIsPageVersionControlled();
    const isProjectTenantVariablesEnabled = useIsProjectTenantVariablesEnabled();
    const isVerticalNavigationEnabled = useIsVerticalNavigationEnabled();
    return (<ErrorContextProvider>
            <ProjectLayoutInternal spaceId={spaceId} projectSlug={projectSlug} branchName={branchName} isNewlyCreatedProject={isNewlyCreatedProject} isPageVersionControlled={isPageVersionControlled} isPageHeaderVNextEnabled={isPageHeaderVNextEnabled} isProjectTenantVariablesEnabled={isProjectTenantVariablesEnabled} isVerticalNavigationEnabled={isVerticalNavigationEnabled}>
                {children}
            </ProjectLayoutInternal>
        </ErrorContextProvider>);
}
type ProjectLayoutInternalProps = PropsWithChildren<{
    spaceId: string;
    projectSlug: string;
    branchName: string | undefined;
    isNewlyCreatedProject: string | null | undefined;
    isPageVersionControlled: boolean;
    isPageHeaderVNextEnabled: boolean;
    isProjectTenantVariablesEnabled: boolean;
    isVerticalNavigationEnabled: boolean;
}>;
interface ProjectLayoutState extends DataBaseComponentState {
    projectGroups: ProjectGroupResource[] | null;
    licenseStatus: LicenseStatusResource | null;
    hasEnvironments: boolean | null;
    newlyCreatedDialogOpen: boolean;
}
class ProjectLayoutInternal extends DataBaseComponent<ProjectLayoutInternalProps, ProjectLayoutState> {
    constructor(props: ProjectLayoutInternalProps) {
        super(props);
        this.state = {
            projectGroups: null,
            licenseStatus: null,
            hasEnvironments: null,
            newlyCreatedDialogOpen: props.isNewlyCreatedProject === "true",
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const projectGroups = repository.ProjectGroups.all();
            const licenseStatus = repository.Licenses.getCurrentStatus();
            const hasEnvironments = repository.Environments.list({ take: 0 }).then((response) => response.TotalResults !== 0);
            this.setState({ projectGroups: await projectGroups, licenseStatus: await licenseStatus, hasEnvironments: await hasEnvironments });
        });
    }
    toBranchSpecifier(branch: string | undefined): BranchSpecifier {
        return branch ? branch : UseDefaultBranch;
    }
    render() {
        const projectSlug = this.props.projectSlug;
        const branchName = this.props.branchName;
        const branchSpecifier = this.toBranchSpecifier(branchName);
        const { isProjectTenantVariablesEnabled } = this.props;
        const logoEditLink = links.projectSettingsPage.generateUrl({ spaceId: this.props.spaceId, projectSlug });
        return (<ProjectContextProvider doBusyTask={this.doBusyTask} projectIdOrSlug={projectSlug} gitRef={branchSpecifier}>
                {({ state }) => {
                const projectLogo = state.model && state.model.Links.Logo;
                const showBusyIndicator = !(state.model || (this.state && this.errors));
                if (!state.model) {
                    if (this.props.isPageHeaderVNextEnabled) {
                        return (<main>
                                    <HideAreaNavPanelWorkaround />
                                    <SkeletonLoadingLayout errors={this.errors}/>
                                </main>);
                    }
                    return (<main>
                                <AreaTitle link={links.projectsPage.generateUrl({ spaceId: this.props.spaceId })} title="Projects" busyIndicator={this.renderBusy(showBusyIndicator)}/>
                                {this.renderErrors()}
                                <NavigationSidebarLayout logoEditLink={logoEditLink} logoUrl={projectLogo} navLinks={[]} content={<></>}/>
                            </main>);
                }
                const navigationSidebarContent = state.model.IsVersionControlled && this.props.isPageVersionControlled && state.gitRefValidationError ? this.getGitErrorPanel(state.gitRefValidationError) : this.props.children;
                return (<main>
                            <SampleProjectTour project={state.model}>
                                {this.renderNewlyCreatedProjectDialog()}
                                {!this.props.isPageHeaderVNextEnabled && this.areaTitle(state, false)}
                                {this.renderErrors()}
                                {this.props.isPageHeaderVNextEnabled ? (<PortalPageLayout navItems={ProjectLinksVNext(branchSpecifier, state.model, state.gitRefValidationError?.Type === ValidateGitRefV2ResponseType.ConnectionFailed, isProjectTenantVariablesEnabled, state.projectExtensionSettingsMetadata)} header={<PageHeaderPrimary breadcrumbs={this.headerBreadcrumbs(state.model)} logo={{ href: projectLogo, accessibleName: "Project Logo" }} title={state.model.Name} titleIcon={state.model.IsVersionControlled ? <GitBranchIcon emphasis="subtle"/> : undefined}/>} tourProgress={<SampleProjectTourResume />} content={navigationSidebarContent} description={state.model.Description}/>) : (<NavigationSidebarLayout logoEditLink={logoEditLink} logoUrl={projectLogo} name={state.model.Name} description={state.model.Description} preNavbarComponent={<div>
                                                {state.model.IsDisabled && (<div>
                                                        <DisabledChip />
                                                    </div>)}
                                                {(state.summary?.HasDeploymentProcess || !state.summary?.HasRunbooks) && <CreateReleaseButton spaceId={state.model.SpaceId} projectId={state.model.Id} projectSlug={state.model.Slug}/>}
                                                <div className={styles.branchSelector}>
                                                    <BranchSelector isPageVersionControlled={this.props.isPageVersionControlled}/>
                                                </div>
                                                <div className={resumeTourButtonMobileContainerStyles}>
                                                    <SampleProjectTourResume />
                                                </div>
                                            </div>} navLinks={ProjectLinks(projectSlug, branchSpecifier, state.model, state.summary, state.gitRefValidationError?.Type === ValidateGitRefV2ResponseType.ConnectionFailed, isProjectTenantVariablesEnabled, state.projectExtensionSettingsMetadata)} content={navigationSidebarContent}/>)}
                                <GettingStartedFooter />
                            </SampleProjectTour>
                        </main>);
            }}
            </ProjectContextProvider>);
    }
    private getGitErrorPanel(error: ValidateGitRefV2Response) {
        if (this.props.isPageHeaderVNextEnabled) {
            return <VcsErrorPage error={error} projectSlug={this.props.projectSlug} spaceId={this.props.spaceId}/>;
        }
        return (<div className={styles.vcsErrorPanelContainer}>
                <VcsErrorPanel content={error.Type === ValidateGitRefV2ResponseType.ConnectionFailed ? (<>
                                Unable to establish a connection to git.{" "}
                                <InternalLink to={links.projectVersionControlSettingsPage.generateUrl({ spaceId: this.props.spaceId, projectSlug: this.props.projectSlug })}>Please check your version control settings</InternalLink>.
                            </>) : (<>{error.Message}</>)}/>
                <SomethingsWrong height="7em" margin="2rem 0"/>
            </div>);
    }
    renderErrors() {
        const errors = this.state && this.errors;
        if (!errors) {
            return null;
        }
        return <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink}/>;
    }
    private renderBusy(forceBusy: boolean) {
        return <BusyFromPromise promise={this.state.busy || forceBusy}>{(busy: boolean) => <LinearProgress variant={"indeterminate"} show={busy || forceBusy}/>}</BusyFromPromise>;
    }
    private renderNewlyCreatedProjectDialog() {
        return (<NewlyCreatedProjectWizardDialog open={this.state.newlyCreatedDialogOpen} close={() => this.setState({ newlyCreatedDialogOpen: false })} showOnboardingQuestionnaire={this.shouldShowOnboardingQuestionnaire()} showCreateEnvironments={this.shouldShowCreateEnvironments()} doBusyTask={this.doBusyTask}/>);
    }
    private headerBreadcrumbs(project: ProjectResource) {
        const foundProjectGroup = this.state.projectGroups && this.state.projectGroups.find((pg) => pg.Id === project.ProjectGroupId);
        if (foundProjectGroup) {
            return [
                { label: "Projects", pageUrl: links.projectsPage.generateUrl({ spaceId: project.SpaceId }) },
                { label: foundProjectGroup.Name, pageUrl: this.props.isVerticalNavigationEnabled ? null : links.projectsPage.generateUrl({ spaceId: project.SpaceId }, { projectGroupId: project.ProjectGroupId }) },
            ];
        }
        return [{ label: "Projects", pageUrl: links.projectsPage.generateUrl({ spaceId: project.SpaceId }) }];
    }
    private areaTitle(state: ProjectContextState, forceBusy: boolean) {
        const hasAccessibleProjectGroup = this.state.projectGroups && state.model.ProjectGroupId && this.state.projectGroups.find((pg) => pg.Id === state.model.ProjectGroupId);
        return !hasAccessibleProjectGroup ? (<AreaTitle link={links.projectsPage.generateUrl({ spaceId: state.model.SpaceId })} title="Projects" busyIndicator={this.renderBusy(forceBusy)}/>) : (<AreaTitle breadcrumbTitle="Projects" breadcrumbPath={links.projectsPage.generateUrl({ spaceId: state.model.SpaceId })} link={links.projectsPage.generateUrl({ spaceId: state.model.SpaceId }, { projectGroupId: state.model.ProjectGroupId })} title={hasAccessibleProjectGroup.Name} busyIndicator={this.renderBusy(forceBusy)}/>);
    }
    private shouldShowOnboardingQuestionnaire() {
        const canShowQuestionnaire = Environment.isInDevelopmentMode() || (this.state.licenseStatus !== null && this.state.licenseStatus.HostingEnvironment !== HostingEnvironment.SelfHosted);
        if (!canShowQuestionnaire) {
            return false;
        }
        const alwaysHideQuestionnaire = localStorage.getItem(alwaysHideOnboardingQuestionnaireKey);
        return alwaysHideQuestionnaire !== "true" && this.props.isNewlyCreatedProject === "true";
    }
    private shouldShowCreateEnvironments() {
        const hasEnvironmentCreatePermission = isAllowed({ permission: Permission.EnvironmentCreate });
        return hasEnvironmentCreatePermission && !this.state.hasEnvironments;
    }
    static displayName = "ProjectLayoutInternal";
}
/*
Currently the routes we have for branch vs non branch based pages co-exist in the same menu.
This means that the `ProjectLayout` currently gets unmounted and remounted between these
routes. This is especially noticeable for the vertical navigation as we show the area nav
panel when the in page menu is not visible. This is exactly what happens when we navigate
between a git based page vs a non git based page when vertical navigation is enabled.
This workaround provides us with a cheap way to make this transition less jarring until
we can find a way to properly refactor the layout to either be mounted higher up in the
tree OR we consolidate routes in some way (possibly using URL query parameters instead).
 */
function HideAreaNavPanelWorkaround() {
    const setVisible = useSetInPageNavVisible();
    React.useEffect(() => {
        setVisible?.(true);
        return () => setVisible?.(false);
    }, [setVisible]);
    return null;
}
// Temporary until new navigation layout is the default
const resumeTourButtonMobileContainerStyles = css({
    maxWidth: 254,
});
