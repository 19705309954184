import type { DashboardFilter, DashboardResource } from "@octopusdeploy/octopus-server-client";
import { useState } from "react";
import { dashboardRefreshIntervalInMs } from "~/areas/dashboard/DashboardOverview/DashboardOverviewPage";
import type { RenderDashboardProps } from "~/areas/dashboard/DashboardOverview/DashboardOverviewPage";
import DashboardDataCube from "~/areas/projects/components/DashboardDataSource/DashboardDataCube";
import type { DataCube } from "~/areas/projects/components/DashboardDataSource/DataCube";
import { repository } from "~/clientInstance";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { useRefreshLoop } from "~/hooks/useRefreshLoop";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
const defaultProjectLimit = 200;
interface SpaceDashboardDataSourceProps {
    render: (props: RenderDashboardProps) => JSX.Element;
    doBusyTask: DoBusyTask;
    onDashboardLoad: () => void;
}
export default function SpaceDashboardDataSource({ doBusyTask, render, onDashboardLoad }: SpaceDashboardDataSourceProps) {
    const [cube, setCube] = useState<DataCube | undefined>(undefined);
    const [projectLimit, setProjectLimit] = useState(defaultProjectLimit);
    const [isFiltered, setIsFiltered] = useState<boolean | undefined>(undefined);
    const dashboardRequest = useDoBusyTaskEffect(doBusyTask, async () => {
        const { cube, projectLimit, isFiltered } = await mainDashboardRefresh();
        setCube(cube);
        setProjectLimit(projectLimit);
        setIsFiltered(isFiltered);
        onDashboardLoad();
    }, [], { timeOperationOptions: timeOperationOptions.forRefresh() });
    useRefreshLoop(dashboardRequest, dashboardRefreshIntervalInMs);
    return render({ cube, projectLimit, isFiltered });
}
async function mainDashboardRefresh(): Promise<{
    cube: DataCube;
    projectLimit: number;
    isFiltered: boolean;
}> {
    const data = await loadDashboardData();
    const deploymentFreezes = isFeatureToggleEnabled("DeploymentFreezeFeatureToggle") ? await repository.DeploymentFreezes.allActive() : { DeploymentFreezes: [] };
    return { cube: new DashboardDataCube(data, data.Tenants, [], [], null, [], [], Promise.resolve([]), deploymentFreezes.DeploymentFreezes), projectLimit: data.ProjectLimit ?? defaultProjectLimit, isFiltered: data.IsFiltered };
}
function loadDashboardData(releaseId?: string): Promise<DashboardResource> {
    const args: DashboardFilter = { highestLatestVersionPerProjectAndEnvironment: true, releaseId };
    return repository.Dashboards.getDashboard(args);
}
