import { AccountType, TriggerActionCategory } from "@octopusdeploy/octopus-server-client";
import type { ProjectInsightsFilterQueryParams } from "@octopusdeploy/portal-routes";
import { allRoutes, generateQueryString, insightsCadenceParam, insightsSplitParam, insightsTenantTagSetIdParam, projectInsightsChannelIdParam, projectInsightsEnvironmentIdParam, projectInsightsTenantIdParam } from "@octopusdeploy/portal-routes";
import Projects from "app/areas/projects/components/Projects/Projects";
import * as React from "react";
import { AuditArchivePage } from "~/areas/configuration/components/AuditLayout/AuditArchivePage";
import { AuditPage } from "~/areas/configuration/components/AuditLayout/AuditPage";
import { BackupPage } from "~/areas/configuration/components/Backup/BackupPage";
import { CreateDeploymentFreezePage } from "~/areas/configuration/components/DeploymentFreezes/CreateDeploymentFreezePage";
import DeploymentFreezesListPage from "~/areas/configuration/components/DeploymentFreezes/DeploymentFreezesListPage";
import { EditDeploymentFreezePage } from "~/areas/configuration/components/DeploymentFreezes/EditDeploymentFreezePage";
import { DetailedServerLogsPage } from "~/areas/configuration/components/Diagnostics/DetailedServerLogsPage";
import { DiagnosticsPage } from "~/areas/configuration/components/Diagnostics/DiagnosticsPage";
import { AutoDeployLogsPage } from "~/areas/configuration/components/Diagnostics/Logs/AutoDeployLogsPage";
import { ScheduledDeployLogsPage } from "~/areas/configuration/components/Diagnostics/Logs/ScheduledDeployLogsPage";
import { SubscriptionLogsPage } from "~/areas/configuration/components/Diagnostics/Logs/SubscriptionLogsPage";
import { ExtensionsPage } from "~/areas/configuration/components/Extensions/ExtensionsPage";
import { FeaturesPage } from "~/areas/configuration/components/FeaturesLayout/FeaturesPage";
import { GitPage } from "~/areas/configuration/components/Git/GitPage";
import { ConfigureLetsEncryptPage } from "~/areas/configuration/components/LetsEncrypt/ConfigureLetsEncryptPage";
import { LetsEncryptPage } from "~/areas/configuration/components/LetsEncrypt/LetsEncryptPage";
import { LicensePage } from "~/areas/configuration/components/License/LicensePage";
import { LicenseUsagePage } from "~/areas/configuration/components/LicenseUsage/LicenseUsagePage";
import { MaintenancePage } from "~/areas/configuration/components/Maintenance/MaintenancePage";
import { NodesPage } from "~/areas/configuration/components/Nodes/NodesPage";
import { ServerSettingsPage } from "~/areas/configuration/components/Nodes/ServerSettingsPage";
import { PerformancePage } from "~/areas/configuration/components/Performance/PerformancePage";
import { UserRolePage } from "~/areas/configuration/components/Roles/UserRolePage";
import { UserRolesPage } from "~/areas/configuration/components/Roles/UserRolesPage";
import { ActiveDirectorySettingsPage } from "~/areas/configuration/components/Settings/ActiveDirectorySettingsPage";
import { ArchiveLimitsSettingsPage } from "~/areas/configuration/components/Settings/ArchiveLimitsSettingsPage";
import { AuthenticationSettingsPage } from "~/areas/configuration/components/Settings/AuthenticationSettingsPage";
import { AzureADSettingsPage } from "~/areas/configuration/components/Settings/AzureADSettingsPage";
import { AzureDevOpsIssueTrackerSettingsPage } from "~/areas/configuration/components/Settings/AzureDevOpsIssueTrackerSettingsPage";
import { EventRetentionSettingsPage } from "~/areas/configuration/components/Settings/EventRetentionSettingsPage";
import { GithubIssueTrackerSettingsPage } from "~/areas/configuration/components/Settings/GithubIssueTrackerSettingsPage";
import { GoogleAppsSettingsPage } from "~/areas/configuration/components/Settings/GoogleAppsSettingsPage";
import { GuestLoginSettingsPage } from "~/areas/configuration/components/Settings/GuestLoginSettingsPage";
import { JiraIntegrationSettingsPage } from "~/areas/configuration/components/Settings/JiraIntegrationSettingsPage";
import { JiraServiceManagementIntegrationSettingsPage } from "~/areas/configuration/components/Settings/JiraServiceManagementIntegrationSettingsPage";
import { LDAPSettingsPage } from "~/areas/configuration/components/Settings/LDAPSettingsPage";
import { OctopusIdSettingsPage } from "~/areas/configuration/components/Settings/OctopusIdSettingsPage";
import { OktaSettingsPage } from "~/areas/configuration/components/Settings/OktaSettingsPage";
import { RetentionPoliciesSettingsPage } from "~/areas/configuration/components/Settings/RetentionPoliciesSettingsPage";
import { ServerFoldersSettingsPage } from "~/areas/configuration/components/Settings/ServerFoldersSettingsPage";
import { ServiceNowIntegrationSettingsPage } from "~/areas/configuration/components/Settings/ServiceNowIntegrationSettingsPage";
import { SettingsListPage } from "~/areas/configuration/components/Settings/SettingsListPage";
import { UpdatesSettingsPage } from "~/areas/configuration/components/Settings/UpdatesSettingsPage";
import { UsernamePasswordSettingsPage } from "~/areas/configuration/components/Settings/UsernamePasswordSettingsPage";
import { WebPortalSettingsPage } from "~/areas/configuration/components/Settings/WebPortalSettingsPage";
import { WebRequestProxySettingsPage } from "~/areas/configuration/components/Settings/WebRequestProxySettingsPage";
import { SigningKeysPage } from "~/areas/configuration/components/SigningKeys/SigningKeysPage";
import { SmtpPage } from "~/areas/configuration/components/Smtp/SmtpPage";
import { EditSpacePage } from "~/areas/configuration/components/Spaces/EditSpacePage";
import { SpacesPage } from "~/areas/configuration/components/Spaces/SpacesPage";
import { SubscriptionPage } from "~/areas/configuration/components/Subscription/SubscriptionPage";
import { SubscriptionsPage } from "~/areas/configuration/components/Subscriptions/SubscriptionsPage";
import { EditTeamPage } from "~/areas/configuration/components/Teams/EditTeamPage";
import { TeamsPage } from "~/areas/configuration/components/Teams/TeamsPage";
import { TelemetryPage } from "~/areas/configuration/components/Telemetry/TelemetryPage";
import { TestPermissionsPage } from "~/areas/configuration/components/TestPermissions/TestPermissionsPage";
import { ThumbprintPage } from "~/areas/configuration/components/Thumbprint/ThumbprintPage";
import { UserInvitePage } from "~/areas/configuration/components/Users/UserInvitePage";
import { CreateUserPage, EditUserPage } from "~/areas/configuration/components/Users/UserPage";
import { UsersPage } from "~/areas/configuration/components/Users/UsersPage";
import { DashboardConfigurationPage } from "~/areas/dashboard/DashboardConfiguration/DashboardConfigurationPage";
import { DashboardOverviewPage } from "~/areas/dashboard/DashboardOverview/DashboardOverviewPage";
import { AccountPage } from "~/areas/infrastructure/components/AccountEdit/AccountPage";
import { AccountsPage } from "~/areas/infrastructure/components/AccountsLayout/AccountsPage";
import { DeploymentTargetSettingsPage } from "~/areas/infrastructure/components/DeploymentTargetSettings/DeploymentTargetSettingsPage";
import { DynamicEnvironmentPage } from "~/areas/infrastructure/components/DynamicEnvironmentLayout/DynamicEnvironmentPage";
import { DynamicEnvironmentsPage } from "~/areas/infrastructure/components/DynamicEnvironmentsLayout/DynamicEnvironmentsPage";
import { EnvironmentPage } from "~/areas/infrastructure/components/EnvironmentLayout/EnvironmentPage";
import { EnvironmentsPage } from "~/areas/infrastructure/components/EnvironmentsLayout/EnvironmentsPage";
import InfrastructureDashboardPage from "~/areas/infrastructure/components/InfrastructureDashboard/index";
import InfrastructureLayout from "~/areas/infrastructure/components/InfrastructureLayout/index";
import { DeploymentTargetConnectionsPage, WorkerMachineConnectionsPage } from "~/areas/infrastructure/components/MachineConnection/MachineConnection";
import { MachineDeploymentsPage } from "~/areas/infrastructure/components/MachineDeployments/MachineDeploymentsPage";
import { DeploymentTargetDiscoveryPage, WorkerMachineDiscoveryPage } from "~/areas/infrastructure/components/MachineDiscovery/MachineDiscovery";
import { MachineEventsPage } from "~/areas/infrastructure/components/MachineEvents/MachineEventsPage";
import { MachineLayout, WorkerMachineLayout } from "~/areas/infrastructure/components/MachineLayout/index";
import { MachinePoliciesPage } from "~/areas/infrastructure/components/MachinePoliciesLayout/MachinePoliciesPage";
import { MachinePolicyPage } from "~/areas/infrastructure/components/MachinePolicyLayout/MachinePolicyPage";
import { MachineRunbookRunsPage } from "~/areas/infrastructure/components/MachineRunbookRuns/MachineRunbookRunsPage";
import { MachineTargetNewPage } from "~/areas/infrastructure/components/MachineSettings/MachineTargetNewPage";
import { DeploymentTargetsPage } from "~/areas/infrastructure/components/MachinesLayout/DeploymentTargetsPage";
import { WorkerMachinesPage } from "~/areas/infrastructure/components/MachinesLayout/WorkerMachinesPage";
import { ProxiesPage } from "~/areas/infrastructure/components/ProxiesLayout/ProxiesPage";
import { ProxyPage } from "~/areas/infrastructure/components/ProxyLayout/ProxyPage";
import { WorkerMachineNewPage } from "~/areas/infrastructure/components/WorkerMachineSettings/WorkerMachineNewPage";
import { WorkerMachineSettingsPage } from "~/areas/infrastructure/components/WorkerMachineSettings/WorkerMachineSettingsPage";
import { WorkerPoolPage } from "~/areas/infrastructure/components/WorkerPools/WorkerPoolLayout/WorkerPoolPage";
import { WorkerPoolsPage } from "~/areas/infrastructure/components/WorkerPools/WorkerPoolsLayout/WorkerPoolsPage";
import { InsightsReportDeploymentDurationPage } from "~/areas/insights/components/Reports/InsightsReportDeploymentDurationPage";
import { InsightsReportDeploymentFailureRatePage } from "~/areas/insights/components/Reports/InsightsReportDeploymentFailureRatePage";
import { InsightsReportDeploymentFrequencyPage } from "~/areas/insights/components/Reports/InsightsReportDeploymentFrequencyPage";
import { InsightsReportDeploymentLeadTimePage } from "~/areas/insights/components/Reports/InsightsReportDeploymentLeadTimePage";
import { InsightsReportDeploymentMeanTimeToRecoveryPage } from "~/areas/insights/components/Reports/InsightsReportDeploymentMeanTimeToRecoveryPage";
import { InsightsReportSettingsPage } from "~/areas/insights/components/Reports/InsightsReportSettingsPage";
import { InsightsReportListPage } from "~/areas/insights/components/Reports/ReportList/InsightsReportListPage";
import type { ReportFilter } from "~/areas/insights/components/Reports/ReportMetricsLayout/useReportFilters";
import { InsightsReportOverviewPage } from "~/areas/insights/components/Reports/ReportOverview/InsightsReportOverviewPage";
import { ReportUpsell } from "~/areas/insights/components/Reports/ReportUpsell/ReportUpsell";
import { ActionTemplatePage } from "~/areas/library/components/ActionTemplates/ActionTemplate/ActionTemplatePage";
import ActionTemplateLayout from "~/areas/library/components/ActionTemplates/ActionTemplateLayout/ActionTemplateLayout";
import { ActionTemplateUsagePage } from "~/areas/library/components/ActionTemplates/ActionTemplateUsagePage";
import { ActionTemplatesPage } from "~/areas/library/components/ActionTemplates/ActionTemplatesPage";
import { BuiltinTemplatesPage } from "~/areas/library/components/ActionTemplates/BuiltinTemplatesPage";
import { CommunityTemplatePage } from "~/areas/library/components/ActionTemplates/CommunityTemplatePage";
import { CommunityTemplatesPage } from "~/areas/library/components/ActionTemplates/CommunityTemplatesPage";
import { RunActionTemplatePage } from "~/areas/library/components/ActionTemplates/RunActionTemplatePage";
import { BuildInformationDetailPage } from "~/areas/library/components/BuildInformation/BuildInformationDetailPage";
import { BuildInformationOverviewPage } from "~/areas/library/components/BuildInformation/BuildInformationOverviewPage";
import { BuildInformationVersionsPage } from "~/areas/library/components/BuildInformation/BuildInformationVersionsPage";
import { BuiltInRepositoryPage } from "~/areas/library/components/BuiltInRepository/BuiltInRepositoryPage";
import { PackageDetailsPage } from "~/areas/library/components/BuiltInRepository/PackageDetailPage";
import { PackageVersionsListPage } from "~/areas/library/components/BuiltInRepository/PackageVersionsListPage";
import { CertificatePage } from "~/areas/library/components/Certificates";
import ArchivedCertificatesPage from "~/areas/library/components/Certificates/ArchivedCertificatesPage";
import CertificatesPage from "~/areas/library/components/Certificates/CertificatesPage";
import { ExternalFeedPage } from "~/areas/library/components/ExternalFeeds/ExternalFeedPage";
import { ExternalFeedTestPage } from "~/areas/library/components/ExternalFeeds/ExternalFeedTestPage";
import { ExternalFeedsPage } from "~/areas/library/components/ExternalFeeds/ExternalFeedsPage";
import { AddGitHubConnectionPage } from "~/areas/library/components/GitConnections/AddGitHubConnectionPage";
import { GitConnectionsPage } from "~/areas/library/components/GitConnections/GitConnectionsPage";
import { GitHubConnectionPage } from "~/areas/library/components/GitConnections/GitHubConnectionPage";
import { GitCredentialPage } from "~/areas/library/components/GitCredentials/GitCredentialPage";
import { GitCredentialsPage } from "~/areas/library/components/GitCredentials/GitCredentialsPage";
import { LifecyclePage } from "~/areas/library/components/Lifecycle/Lifecycle/LifecyclePage";
import { LifecyclesPage } from "~/areas/library/components/Lifecycle/Lifecycles/LifecyclesPage";
import { ScriptModulePage } from "~/areas/library/components/Scripts/ScriptModulePage";
import { ScriptModulesPage } from "~/areas/library/components/Scripts/ScriptModulesPage";
import { TagSetPage } from "~/areas/library/components/TagSets/TagSetPage";
import { TagSetsPage } from "~/areas/library/components/TagSets/TagSetsPage";
import { EditVariableSetPage } from "~/areas/library/components/VariableSets/EditVariableSetPage";
import { VariableSetsPage } from "~/areas/library/components/VariableSets/VariableSetsPage";
import { ChannelsWithMatch } from "~/areas/projects/components/Channels/Channels";
import { Edit as EditChannel } from "~/areas/projects/components/Channels/Edit";
import DeploymentProcessSettings from "~/areas/projects/components/DeploymentProcessSettings/DeploymentProcessSettings";
import DeploymentsOverview from "~/areas/projects/components/DeploymentsOverview";
import { ExportProjectTaskPage } from "~/areas/projects/components/ImportExport/ExportProjectTaskPage";
import { ExportProjectsPage } from "~/areas/projects/components/ImportExport/ExportProjectsPage";
import { ImportExportProjectTasksPage } from "~/areas/projects/components/ImportExport/ImportExportProjectTasksPage";
import { ImportProjectTaskPage } from "~/areas/projects/components/ImportExport/ImportProjectTaskPage";
import { ImportProjectsPage } from "~/areas/projects/components/ImportExport/ImportProjectsPage";
import { DeploymentProcessCodePage } from "~/areas/projects/components/Process/DeploymentProcessCodePage";
import { DeploymentProcessListPage } from "~/areas/projects/components/Process/DeploymentProcessListPage";
import { DeploymentProcessStepsPage } from "~/areas/projects/components/Process/DeploymentProcessStepsPage";
import { ProjectInsightsDeploymentDurationPage } from "~/areas/projects/components/ProjectInsights/ProjectInsightsDeploymentDuration/ProjectInsightsDeploymentDurationPage";
import { ProjectInsightsFailureRatePage } from "~/areas/projects/components/ProjectInsights/ProjectInsightsFailureRate/ProjectInsightsFailureRatePage";
import { ProjectInsightsFrequencyPage } from "~/areas/projects/components/ProjectInsights/ProjectInsightsFrequency/ProjectInsightsFrequencyPage";
import { ProjectInsightsLeadTimePage } from "~/areas/projects/components/ProjectInsights/ProjectInsightsLeadTime/ProjectInsightsLeadTimePage";
import { ProjectInsightsOverviewPage } from "~/areas/projects/components/ProjectInsights/ProjectInsightsOverview/ProjectInsightsOverviewPage";
import { ProjectInsightsTimeToRecoveryPage } from "~/areas/projects/components/ProjectInsights/ProjectInsightsTimeToRecovery/ProjectInsightsTimeToRecoveryPage";
import { DynamicEnvironmentSettingsPage } from "~/areas/projects/components/ProjectSettings/DynamicEnvironments/DynamicEnvironmentSettingsPage";
import { ITSMProvidersSettingsPage } from "~/areas/projects/components/ProjectSettings/ITSMProvidersSettingsPage";
import { ProjectSettingsPage } from "~/areas/projects/components/ProjectSettings/ProjectSettingsPage";
import { VersionControlSettingsPage } from "~/areas/projects/components/ProjectSettings/VersionControl/VersionControlSettingsPage";
import ProjectTasks from "~/areas/projects/components/ProjectTasks/ProjectTasks";
import ProjectTenantsPage from "~/areas/projects/components/ProjectTenants/ProjectTenantsPage";
import type { CreateDeploymentGoal } from "~/areas/projects/components/Releases/Deployments/CreateDeploymentPage";
import { CreateDeploymentPage } from "~/areas/projects/components/Releases/Deployments/CreateDeploymentPage";
import { DeploymentDetailsPage } from "~/areas/projects/components/Releases/Deployments/DeploymentDetailsPage";
import { CreateOrEditReleasePage } from "~/areas/projects/components/Releases/Edit/CreateOrEditReleasePage";
import { ReleasePage } from "~/areas/projects/components/Releases/ReleasePage";
import { ReleasesPage } from "~/areas/projects/components/Releases/ReleasesPage";
import { OperationsOverviewPage } from "~/areas/projects/components/Runbooks/OperationsOverviewPage";
import RunbookOverviewPage from "~/areas/projects/components/Runbooks/RunbookOverviewPage";
import { RunbookProcessListPage } from "~/areas/projects/components/Runbooks/RunbookProcessListPage";
import { RunbookProcessStepsPage } from "~/areas/projects/components/Runbooks/RunbookProcessStepsPage";
import { RunbookRunDetailsPage } from "~/areas/projects/components/Runbooks/RunbookRunDetailsPage";
import type { RunGoal } from "~/areas/projects/components/Runbooks/RunbookRunNowLayout";
import { RunbookRunNowPage } from "~/areas/projects/components/Runbooks/RunbookRunNowPage";
import { RunbookRunsListPage } from "~/areas/projects/components/Runbooks/RunbookRunsListPage";
import { RunbookSettingsPage } from "~/areas/projects/components/Runbooks/RunbookSettingsPage";
import { CreateRunbookRunForSnapshotPage } from "~/areas/projects/components/Runbooks/RunbookSnapshots/CreateRunbookRunForSnapshotPage";
import { RunbookSnapshotEditPage } from "~/areas/projects/components/Runbooks/RunbookSnapshots/RunbookSnapshotEditPage";
import { RunbookSnapshotInfoPage } from "~/areas/projects/components/Runbooks/RunbookSnapshots/RunbookSnapshotInfoPage";
import { RunbookSnapshotsPage } from "~/areas/projects/components/Runbooks/RunbookSnapshots/RunbookSnapshotsPage";
import { RunbooksPage } from "~/areas/projects/components/Runbooks/RunbooksPage";
import { CreateRunbookScheduledTriggersPage } from "~/areas/projects/components/Runbooks/Triggers/CreateRunbookScheduledTriggersPage";
import { EditRunbookScheduledTriggersPage } from "~/areas/projects/components/Runbooks/Triggers/EditRunbookScheduledTriggersPage";
import { RunbookTriggersPage } from "~/areas/projects/components/Runbooks/Triggers/RunbookTriggersPage";
import { DeploymentTriggerPage } from "~/areas/projects/components/Triggers/DeploymentTriggerPage";
import { CreateBuiltInFeedTriggerPage } from "~/areas/projects/components/Triggers/Feed/CreateBuiltInFeedTriggerPage";
import { CreateReleaseCreationFeedTriggersPage } from "~/areas/projects/components/Triggers/Feed/CreateReleaseCreationFeedTriggersPage";
import { EditBuiltInFeedTriggerPage } from "~/areas/projects/components/Triggers/Feed/EditBuiltInFeedTriggerPage";
import { EditReleaseCreationFeedTriggersPage } from "~/areas/projects/components/Triggers/Feed/EditReleaseCreationFeedTriggersPage";
import { CreateDeploymentScheduledTriggerPage } from "~/areas/projects/components/Triggers/Scheduled/CreateDeploymentScheduledTriggerPage";
import { EditDeploymentScheduledTriggerPage } from "~/areas/projects/components/Triggers/Scheduled/EditDeploymentScheduledTriggerPage";
import { TriggersPage } from "~/areas/projects/components/Triggers/TriggersPage";
import AllVariables from "~/areas/projects/components/Variables/AllVariables";
import CommonVariableTemplates from "~/areas/projects/components/Variables/CommonVariableTemplates";
import LibraryVariableSets from "~/areas/projects/components/Variables/LibraryVariableSets/LibraryVariableSets";
import ProjectVariableTemplates from "~/areas/projects/components/Variables/ProjectVariableTemplates";
import ProjectVariables from "~/areas/projects/components/Variables/ProjectVariables/ProjectVariables";
import ProjectTenantCommonTemplates from "~/areas/projects/components/Variables/TenantVariables/ProjectTenantCommonTemplates";
import ProjectTenantProjectTemplates from "~/areas/projects/components/Variables/TenantVariables/ProjectTenantProjectTemplates";
import VariablePreview from "~/areas/projects/components/Variables/VariablePreview/VariablePreview";
import { RawLogPage } from "~/areas/tasks/components/RawLog/RawLogPage";
import { ScriptConsolePage } from "~/areas/tasks/components/ScriptConsole/ScriptConsolePage";
import { TaskPage } from "~/areas/tasks/components/Task/TaskPage";
import { TenantOverviewPage } from "~/areas/tenants/TenantOverview/TenantOverviewPage";
import { TenantSettingsPage } from "~/areas/tenants/TenantSettings/TenantSettingsPage";
import { TenantTasksPage } from "~/areas/tenants/TenantTasks/TenantTasksPage";
import { TenantVariablesPage } from "~/areas/tenants/TenantVariables/TenantVariablesPage";
import { TenantsPage } from "~/areas/tenants/Tenants/TenantsPage";
import UserApiKeysPage from "~/areas/users/UserApiKeys/UserApiKeysPage";
import UserLoginsPage from "~/areas/users/UserLogins/UserLoginsPage";
import UserProfilePage from "~/areas/users/UserProfile/UserProfilePage";
import UserRecentActivityPage from "~/areas/users/UserRecentActivity/UserRecentActivityPage";
import { StyleGuidePage } from "~/components/StyleGuide/StyleGuidePage";
import { createInfrastructurePageRegistration } from "~/routing/pageRegistrations/InfrastructurePageRegistration";
import { createInsightsPageRegistration, createInsightsReportPageRegistration } from "~/routing/pageRegistrations/InsightsPageRegistration";
import { createTenantPageRegistration } from "~/routing/pageRegistrations/TenantPageRegistration";
import { createPageMap } from "~/routing/pageRegistrations/createPageMap";
import type { PageGroup } from "~/routing/pageRegistrations/createPageMap";
import { ProjectGroupPage } from "../../areas/projects/components/ProjectGroupLayout/ProjectGroupPage";
import { TasksPage } from "../../areas/tasks/components/Tasks/TasksPage";
import { createConfigurationPageRegistration } from "./ConfigurationPageRegistration";
import { createCurrentUserPageRegistration } from "./CurrentUserPageRegistration";
import { createLibraryPageRegistration } from "./LibraryPageRegistration";
import type { UnknownPageRegistration } from "./PageRegistration";
import { createProjectLevel1PageRegistration, createProjectPageRegistration } from "./ProjectPageRegistration";
import { createSpaceRootPageRegistration } from "./SpaceRootPageRegistration";
import { createTaskPageRegistration } from "./TaskPageRegistration";
/*These are experimental routes and won't have any impact on existing routes as we define them today.
The better portal experience team is experimenting with adding an abstraction layer for our routing.*/
export const allPages = createPageMap(allRoutes, {
    partialRoute: (r) => r,
    pages: {},
    childGroups: {
        system: {
            pages: {
                tasksPage: (r) => createTaskPageRegistration(r, () => <TasksPage />, { pageArea: "Tasks", pageId: "Tasks", pageName: "Tasks" }),
                taskPage: (r) => createTaskPageRegistration(r, ({ taskId }) => <TaskPage taskId={taskId}/>, { pageArea: "Tasks", pageId: "Task", pageName: "Task" }),
                taskRawLogPage: (r) => createTaskPageRegistration(r, ({ taskId }) => <RawLogPage taskId={taskId}/>, { pageArea: "Tasks", pageId: "Task.Raw", pageName: "Raw Task Log" }),
                scriptConsolePage: (r) => createTaskPageRegistration(r, () => <ScriptConsolePage />, { pageArea: "Tasks", pageId: "Task.ScriptConsole", pageName: "Script Console" }),
                styleGuidePage: (r) => createTaskPageRegistration(r, () => <StyleGuidePage />, { pageArea: "Dev Tools", pageId: "StyleGuide", pageName: "Style guide" }),
            },
            partialRoute: (r) => r,
            childGroups: {
                configuration: {
                    partialRoute: (r) => r,
                    childGroups: {
                        settings: {
                            partialRoute: (r) => r,
                            childGroups: {},
                            pages: {
                                settingsListPage: (r) => createConfigurationPageRegistration(r, () => <SettingsListPage />, { pageArea: "Configuration", pageId: "Configuration.Settings", pageName: "Settings" }),
                                activeDirectorySettingsPage: (r) => createConfigurationPageRegistration(r, () => <ActiveDirectorySettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.authentication-directoryservices`, pageName: "Active Directory Settings" }),
                                archiveLimitsSettingsPage: (r) => createConfigurationPageRegistration(r, () => <ArchiveLimitsSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.archive-limit`, pageName: "Archive Limits Settings" }),
                                authenticationSettingsPage: (r) => createConfigurationPageRegistration(r, () => <AuthenticationSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.authentication`, pageName: "Authentication Settings" }),
                                azureADSettingsPage: (r) => createConfigurationPageRegistration(r, () => <AzureADSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.authentication-aad`, pageName: "Azure AD Settings" }),
                                azureDevOpsIssueTrackerSettingsPage: (r) => createConfigurationPageRegistration(r, () => <AzureDevOpsIssueTrackerSettingsPage />, {
                                    pageArea: "Configuration",
                                    pageId: `Configuration.Setting.issuetracker-azuredevops-v2`,
                                    pageName: "Azure DevOps Issue Tracker Settings",
                                }),
                                eventRetentionSettingsPage: (r) => createConfigurationPageRegistration(r, () => <EventRetentionSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.eventretention`, pageName: "Event Retention Settings" }),
                                githubIssueTrackerSettingsPage: (r) => createConfigurationPageRegistration(r, () => <GithubIssueTrackerSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.issuetracker-github`, pageName: "GitHub Issue Tracker Settings" }),
                                googleAppsSettingsPage: (r) => createConfigurationPageRegistration(r, () => <GoogleAppsSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.authentication-googleapps`, pageName: "Google Apps Settings" }),
                                guestLoginSettingsPage: (r) => createConfigurationPageRegistration(r, () => <GuestLoginSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.authentication-guest`, pageName: "Guest Login Settings" }),
                                jiraIntegrationSettingsPage: (r) => createConfigurationPageRegistration(r, () => <JiraIntegrationSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.jira-integration`, pageName: "Jira Integration Settings" }),
                                jiraServiceManagementIntegrationSettingsPage: (r) => createConfigurationPageRegistration(r, () => <JiraServiceManagementIntegrationSettingsPage />, {
                                    pageArea: "Configuration",
                                    pageId: `Configuration.Setting.jiraservicemanagement-integration`,
                                    pageName: "Jira Service Management Integration Settings",
                                }),
                                ldapSettingsPage: (r) => createConfigurationPageRegistration(r, () => <LDAPSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.authentication-ldap`, pageName: "LDAP Settings" }),
                                octopusIdSettingsPage: (r) => createConfigurationPageRegistration(r, () => <OctopusIdSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.authentication-octopusid`, pageName: "Octopus ID Settings" }),
                                oktaSettingsPage: (r) => createConfigurationPageRegistration(r, () => <OktaSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.authentication-od`, pageName: "Okta Settings" }),
                                retentionPoliciesSettingsPage: (r) => createConfigurationPageRegistration(r, () => <RetentionPoliciesSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.retentionpolicies`, pageName: "Retention Policies Settings" }),
                                serverFoldersSettingsPage: (r) => createConfigurationPageRegistration(r, () => <ServerFoldersSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.server-folders`, pageName: "Server Folders Settings" }),
                                serviceNowIntegrationSettingsPage: (r) => createConfigurationPageRegistration(r, () => <ServiceNowIntegrationSettingsPage />, {
                                    pageArea: "Configuration",
                                    pageId: `Configuration.Setting.servicenow-integration`,
                                    pageName: "ServiceNow Integration Settings",
                                }),
                                updatesSettingsPage: (r) => createConfigurationPageRegistration(r, () => <UpdatesSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.upgrades`, pageName: "Updates Settings" }),
                                usernamePasswordSettingsPage: (r) => createConfigurationPageRegistration(r, () => <UsernamePasswordSettingsPage />, {
                                    pageArea: "Configuration",
                                    pageId: `Configuration.Setting.authentication-usernamepassword`,
                                    pageName: "Username / Password Settings",
                                }),
                                webPortalSettingsPage: (r) => createConfigurationPageRegistration(r, () => <WebPortalSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.webportal`, pageName: "Web Portal Settings" }),
                                webRequestProxySettingsPage: (r) => createConfigurationPageRegistration(r, () => <WebRequestProxySettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.server-proxy`, pageName: "Web Request Proxy Settings" }),
                            },
                        },
                    },
                    pages: {
                        auditPage: (r) => createConfigurationPageRegistration(r, () => <AuditPage />, { pageArea: "Configuration", pageId: "Configuration.Audit", pageName: "Audit" }),
                        auditArchivePage: (r) => createConfigurationPageRegistration(r, () => <AuditArchivePage />, { pageArea: "Configuration", pageId: "Configuration.AuditArchive", pageName: "Audit Archive" }),
                        backupPage: (r) => createConfigurationPageRegistration(r, () => <BackupPage />, { pageArea: "Configuration", pageId: "Configuration.Backup", pageName: "Backup" }),
                        diagnosticsPage: (r) => createConfigurationPageRegistration(r, () => <DiagnosticsPage />, { pageArea: "Configuration", pageId: "Configuration.Diagnostics", pageName: "Diagnostics" }),
                        diagnosticLogsPage: (r) => createConfigurationPageRegistration(r, () => <DetailedServerLogsPage />, { pageArea: "Configuration", pageId: "Configuration.DiagnosticLogs", pageName: "Diagnostic Logs" }),
                        diagnosticAutoDeployLogsPage: (r) => createConfigurationPageRegistration(r, () => <AutoDeployLogsPage />, { pageArea: "Configuration", pageId: "Configuration.DiagnosticLogsAutoDeploy", pageName: "Auto Deploy Diagnostic Logs" }),
                        diagnosticScheduledDeployLogsPage: (r) => createConfigurationPageRegistration(r, () => <ScheduledDeployLogsPage />, { pageArea: "Configuration", pageId: "Configuration.DiagnosticLogsScheduledDeploy", pageName: "Scheduled Deploy Diagnostic Logs" }),
                        diagnosticSubscriptionLogsPage: (r) => createConfigurationPageRegistration(r, () => <SubscriptionLogsPage />, { pageArea: "Configuration", pageId: "Configuration.DiagnosticLogsSubscription", pageName: "Subscription Diagnostic Logs" }),
                        extensionsPage: (r) => createConfigurationPageRegistration(r, () => <ExtensionsPage />, { pageArea: "Configuration", pageId: "Configuration.Extensions", pageName: "Extensions" }),
                        featuresPage: (r) => createConfigurationPageRegistration(r, () => <FeaturesPage />, { pageArea: "Configuration", pageId: "Configuration.Features", pageName: "Features" }),
                        letsEncryptPage: (r) => createConfigurationPageRegistration(r, () => <LetsEncryptPage />, { pageArea: "Configuration", pageId: "Configuration.LetsEncrypt", pageName: "Let's Encrypt" }),
                        configureLetsEncryptPage: (r) => createConfigurationPageRegistration(r, () => <ConfigureLetsEncryptPage />, { pageArea: "Configuration", pageId: "Configuration.LetsEncryptConfigure", pageName: "Configure Let's Encrypt" }),
                        maintenancePage: (r) => createConfigurationPageRegistration(r, () => <MaintenancePage />, { pageArea: "Configuration", pageId: "Configuration.Maintenance", pageName: "Maintenance" }),
                        performancePage: (r) => createConfigurationPageRegistration(r, () => <PerformancePage />, { pageArea: "Configuration", pageId: "Configuration.Performance", pageName: "Performance" }),
                        serverSettingsPage: (r) => createConfigurationPageRegistration(r, () => <ServerSettingsPage />, { pageArea: "Configuration", pageId: "Configuration.NodesServerSettings", pageName: "Node Server Settings" }),
                        nodesPage: (r) => createConfigurationPageRegistration(r, () => <NodesPage />, { pageArea: "Configuration", pageId: "Configuration.Nodes", pageName: "Nodes" }),
                        licensePage: (r) => createConfigurationPageRegistration(r, (PageLayout) => <LicensePage PageLayout={PageLayout}/>, { pageArea: "Configuration", pageId: "Configuration.License", pageName: "License" }),
                        licenseUsagePage: (r) => createConfigurationPageRegistration(r, (PageLayout) => <LicenseUsagePage PageLayout={PageLayout}/>, { pageArea: "Configuration", pageId: "Configuration.LicenseUsage", pageName: "License Usage Dashboard" }),
                        smtpPage: (r) => createConfigurationPageRegistration(r, () => <SmtpPage />, { pageArea: "Configuration", pageId: "Configuration.Smtp", pageName: "SMTP" }),
                        gitPage: (r) => createConfigurationPageRegistration(r, (PageLayout) => <GitPage PageLayout={PageLayout}/>, { pageArea: "Configuration", pageId: "Configuration.Git", pageName: "Git" }),
                        subscriptionsPage: (r) => createConfigurationPageRegistration(r, () => <SubscriptionsPage />, { pageArea: "Configuration", pageId: "Configuration.Subscriptions", pageName: "Subscriptions" }),
                        editSubscriptionPage: (r) => createConfigurationPageRegistration(r, (PageLayout, { subscriptionId }) => <SubscriptionPage subscriptionId={subscriptionId}/>, {
                            pageArea: "Configuration",
                            pageId: "Configuration.Subscription",
                            pageName: "Subscription",
                        }),
                        createSubscriptionPage: (r) => createConfigurationPageRegistration(r, () => <SubscriptionPage create/>, { pageArea: "Configuration", pageId: "Configuration.SubscriptionsNew", pageName: "SubscriptionsNew" }),
                        testPermissionsPage: (r) => createConfigurationPageRegistration(r, () => <TestPermissionsPage userId={undefined}/>, { pageArea: "Configuration", pageId: "Configuration.TestPermission", pageName: "Test Permission" }),
                        testPermissionsForUserPage: (r) => createConfigurationPageRegistration(r, (PageLayout, { userId }) => <TestPermissionsPage userId={userId}/>, { pageArea: "Configuration", pageId: "Configuration.TestPermissions", pageName: "Test Permissions" }),
                        thumbprintPage: (r) => createConfigurationPageRegistration(r, () => <ThumbprintPage />, { pageArea: "Configuration", pageId: "Configuration.Thumbprint", pageName: "Thumbprint" }),
                        userRolesPage: (r) => createConfigurationPageRegistration(r, () => <UserRolesPage />, { pageArea: "Configuration", pageId: "Configuration.Roles", pageName: "User Roles" }),
                        createUserRolePage: (r) => createConfigurationPageRegistration(r, () => <UserRolePage roleId={undefined}/>, { pageArea: "Configuration", pageId: "Configuration.RolesNew", pageName: "Add New User Role" }),
                        editUserRolePage: (r) => createConfigurationPageRegistration(r, (PageLayout, { roleId }) => <UserRolePage roleId={roleId}/>, { pageArea: "Configuration", pageId: "Configuration.Role", pageName: "User Role" }),
                        spacesPage: (r) => createConfigurationPageRegistration(r, () => <SpacesPage />, { pageArea: "Configuration", pageId: "Configuration.Spaces", pageName: "Spaces" }),
                        editSpacePage: (r) => createConfigurationPageRegistration(r, (PageLayout, { currentSpaceId }) => <EditSpacePage currentSpaceId={currentSpaceId}/>, { pageArea: "Configuration", pageId: "Configuration.Space", pageName: "Space" }),
                        teamsPage: (r) => createConfigurationPageRegistration(r, () => <TeamsPage />, { pageArea: "Configuration", pageId: "Configuration.Teams", pageName: "Teams" }),
                        editTeamPage: (r) => createConfigurationPageRegistration(r, (PageLayout, { teamId }) => <EditTeamPage teamId={teamId}/>, { pageArea: "Configuration", pageId: "Configuration.Team", pageName: "Team" }),
                        usersPage: (r) => createConfigurationPageRegistration(r, () => <UsersPage />, { pageArea: "Configuration", pageId: "Configuration.Users", pageName: "Users" }),
                        createUserPage: (r) => createConfigurationPageRegistration(r, () => <CreateUserPage />, { pageArea: "Configuration", pageId: "Configuration.UsersNew", pageName: "Add New User" }),
                        editUserPage: (r) => createConfigurationPageRegistration(r, (PageLayout, { userId }) => <EditUserPage userId={userId}/>, { pageArea: "Configuration", pageId: "Configuration.User", pageName: "User" }),
                        signingKeysPage: (r) => createConfigurationPageRegistration(r, () => <SigningKeysPage />, { pageArea: "Configuration", pageId: "Configuration.SigningKeys", pageName: "Signing Keys" }),
                        telemetryPage: (r) => createConfigurationPageRegistration(r, () => <TelemetryPage />, { pageArea: "Configuration", pageId: "Configuration.Telemetry", pageName: "Telemetry" }),
                        userInvitesPage: (r) => createConfigurationPageRegistration(r, () => <UserInvitePage />, { pageArea: "Configuration", pageId: "Configuration.UserInvites", pageName: "Invite Users" }),
                        deploymentFreezesListPage: (r) => createConfigurationPageRegistration(r, () => <DeploymentFreezesListPage />, { pageArea: "Configuration", pageId: "Configuration.DeploymentFreezes", pageName: "Deployment Freezes" }),
                        deploymentFreezesCreatePage: (r) => createConfigurationPageRegistration(r, () => <CreateDeploymentFreezePage />, { pageArea: "Configuration", pageId: "Configuration.DeploymentFreezesNew", pageName: "Add Deployment Freeze" }),
                        deploymentFreezesEditPage: (r) => createConfigurationPageRegistration(r, (PageLayout, { deploymentFreezeId }) => <EditDeploymentFreezePage deploymentFreezeId={deploymentFreezeId}/>, {
                            pageArea: "Configuration",
                            pageId: "Configuration.DeploymentFreezesEdit",
                            pageName: "Edit Deployment Freeze",
                        }),
                    },
                },
                currentUser: {
                    partialRoute: (r) => r,
                    childGroups: {},
                    pages: {
                        currentUserDetailsPage: (r) => createCurrentUserPageRegistration(r, () => <UserProfilePage />, { pageArea: "User Profile", pageId: "UserProfile.Details", pageName: "User Profile Details" }),
                        currentUserLoginsPage: (r) => createCurrentUserPageRegistration(r, () => <UserLoginsPage />, { pageArea: "User Profile", pageId: "UserProfile.Logins", pageName: "User Profile Logins" }),
                        currentUserApiKeysPage: (r) => createCurrentUserPageRegistration(r, () => <UserApiKeysPage />, { pageArea: "User Profile", pageId: "UserProfile.ApiKeys", pageName: "User Profile API Keys" }),
                        currentUserActivityPage: (r) => createCurrentUserPageRegistration(r, () => <UserRecentActivityPage />, { pageArea: "User Profile", pageId: "UserProfile.Activity", pageName: "User Profile Activity" }),
                    },
                },
            },
        },
        space: {
            partialRoute: (r) => r,
            pages: {
                dashboardPage: (r) => createSpaceRootPageRegistration(r, ({ spaceId }) => <DashboardOverviewPage spaceId={spaceId}/>, { pageArea: "Dashboard", pageName: "Dashboard", pageId: "Dashboard" }, "Projects"),
                dashboardConfigurationPage: (r) => createSpaceRootPageRegistration(r, ({ spaceId }) => <DashboardConfigurationPage spaceId={spaceId}/>, { pageArea: "Dashboard", pageName: "Configure Dashboard", pageId: "Dashboard.Configure" }, "Projects"),
                editProjectGroupPage: (r) => createSpaceRootPageRegistration(r, ({ spaceId, projectGroupId }) => <ProjectGroupPage spaceId={spaceId} projectGroupId={projectGroupId}/>, { pageArea: "Project", pageName: "Project Group", pageId: "ProjectGroup.Edit" }, "Projects"),
            },
            childGroups: {
                projects: {
                    partialRoute: (r) => r,
                    pages: {
                        projectsPage: (r) => createProjectLevel1PageRegistration(r, (PageLayout, { spaceId }) => <Projects PageLayout={PageLayout} spaceId={spaceId}/>, { pageArea: "Project", pageName: "Projects", pageId: "Projects" }),
                    },
                    childGroups: {
                        specificProject: {
                            partialRoute: (r) => r,
                            pages: {},
                            childGroups: {
                                branch: {
                                    partialRoute: (r) => r,
                                    pages: {
                                        branchProjectRunbooksPage: (r) => createProjectPageRegistration(r, () => <RunbooksPage />, { pageArea: "Project", pageId: "Project.Operations.Runbooks", pageName: "Runbooks" }),
                                    },
                                    childGroups: {
                                        variables: {
                                            partialRoute: (r) => r,
                                            childGroups: {},
                                            pages: {
                                                branchAllVariablesPage: (r) => createProjectPageRegistration(r, () => <AllVariables />, { pageArea: "Project", pageId: "Project.VariableAll", pageName: "All Variables" }),
                                                branchVariablesPage: (r) => createProjectPageRegistration(r, () => <ProjectVariables />, { pageArea: "Project", pageId: "Project.Variables", pageName: "Variables" }),
                                                branchVariablesPreviewPage: (r) => createProjectPageRegistration(r, () => <VariablePreview />, { pageArea: "Project", pageId: "Project.VariablePreview", pageName: "Variables Preview" }),
                                            },
                                        },
                                        deployments: {
                                            partialRoute: (r) => r,
                                            childGroups: {},
                                            pages: {
                                                branchDeploymentProcessPage: (r) => createProjectPageRegistration(r, () => <DeploymentProcessListPage />, { pageArea: "Project", pageId: "Project.Process", pageName: "Deployment Process" }),
                                                branchDeploymentProcessCodePage: (r) => createProjectPageRegistration(r, () => <DeploymentProcessCodePage />, { pageArea: "Project", pageId: "Project.Process", pageName: "Deployment Process Code" }),
                                                branchDeploymentProcessStepsPage: (r) => createProjectPageRegistration(r, () => <DeploymentProcessStepsPage />, { pageArea: "Project", pageId: "Project.Process", pageName: "Deployment Process" }),
                                                branchDeploymentProcessSettingsPage: (r) => createProjectPageRegistration(r, () => <DeploymentProcessSettings />, { pageArea: "Project", pageId: "Project.DeploymentSettings", pageName: "Settings" }),
                                            },
                                        },
                                    },
                                },
                                withoutBranch: {
                                    partialRoute: (r) => r,
                                    pages: {
                                        projectInsightsOverviewPage: (r) => createProjectPageRegistration(r, () => <ProjectInsightsOverviewPage />, { pageArea: "Project", pageId: "Project.Insights.Overview", pageName: "Insights Overview" }),
                                        projectInsightsLeadTimePage: (r) => createProjectPageRegistration(r, () => <ProjectInsightsLeadTimePage />, { pageArea: "Project", pageId: "Project.Insights.LeadTime", pageName: "Lead Time" }),
                                        projectInsightsFrequencyPage: (r) => createProjectPageRegistration(r, () => <ProjectInsightsFrequencyPage />, { pageArea: "Project", pageId: "Project.Insights.Frequency", pageName: "Deployment Frequency" }),
                                        projectInsightsFailureRatePage: (r) => createProjectPageRegistration(r, () => <ProjectInsightsFailureRatePage />, { pageArea: "Project", pageId: "Project.Insights.FailureRate", pageName: "Failure Rate" }),
                                        projectInsightsTimeToRecoveryPage: (r) => createProjectPageRegistration(r, () => <ProjectInsightsTimeToRecoveryPage />, { pageArea: "Project", pageId: "Project.Insights.TimeToRecovery", pageName: "Mean Time To Recovery" }),
                                        projectInsightsDeploymentDurationPage: (r) => createProjectPageRegistration(r, () => <ProjectInsightsDeploymentDurationPage />, { pageArea: "Project", pageId: "Project.Insights.DeploymentDuration", pageName: "Deployment Duration" }),
                                        projectTasksPage: (r) => createProjectPageRegistration(r, () => <ProjectTasks />, { pageArea: "Project", pageId: "Project.Tasks", pageName: "Tasks" }),
                                        projectTenantsPage: (r) => createProjectPageRegistration(r, ({ spaceId }) => <ProjectTenantsPage spaceId={spaceId}/>, { pageArea: "Project", pageId: "Project.Tenants", pageName: "Tenants" }),
                                        projectOperationsPage: (r) => createProjectPageRegistration(r, () => <OperationsOverviewPage />, { pageArea: "Project", pageId: "Project.Operations", pageName: "Operations" }),
                                        projectRunbookTriggersPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug }) => <RunbookTriggersPage spaceId={spaceId} projectSlug={projectSlug}/>, {
                                            pageArea: "Project",
                                            pageId: "Project.Runbook.Triggers",
                                            pageName: "Runbook Triggers",
                                        }),
                                        projectRunbookCreateScheduledTriggerForRunbookPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug, runbookId }) => <CreateRunbookScheduledTriggersPage spaceId={spaceId} projectSlug={projectSlug} runbookId={runbookId}/>, {
                                            pageArea: "Project",
                                            pageId: "Project.Runbook.ScheduledTriggerNew",
                                            pageName: "Add New Runbook Scheduled Trigger",
                                        }),
                                        projectRunbookCreateScheduledTriggerPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug }) => <CreateRunbookScheduledTriggersPage spaceId={spaceId} projectSlug={projectSlug}/>, {
                                            pageArea: "Project",
                                            pageId: "Project.Runbook.ScheduledTrigger",
                                            pageName: "Runbook Scheduled Trigger",
                                        }),
                                        projectRunbookEditScheduledTriggerPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug, triggerId }) => <EditRunbookScheduledTriggersPage spaceId={spaceId} projectSlug={projectSlug} triggerId={triggerId}/>, {
                                            pageArea: "Project",
                                            pageId: "Project.Runbook.Triggers",
                                            pageName: "Runbook Triggers",
                                        }),
                                        deploymentTriggersPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug }) => <TriggersPage spaceId={spaceId} projectSlug={projectSlug} triggerActionCategory={TriggerActionCategory.Deployment}/>, {
                                            pageArea: "Project",
                                            pageId: "Project.Triggers",
                                            pageName: "Triggers",
                                        }),
                                        createDeploymentTriggerPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug }) => <DeploymentTriggerPage spaceId={spaceId} projectSlug={projectSlug} branchName={undefined} create={true}/>, {
                                            pageArea: "Project",
                                            pageId: "Project.DeploymentTargetTriggerNew",
                                            pageName: "Add New Deployment Target Trigger",
                                        }),
                                        editDeploymentTriggerPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug, triggerId }) => <DeploymentTriggerPage spaceId={spaceId} projectSlug={projectSlug} branchName={undefined} triggerId={triggerId}/>, {
                                            pageArea: "Project",
                                            pageId: "Project.DeploymentTargetTrigger",
                                            pageName: "Deployment Target Trigger",
                                        }),
                                        createDeploymentScheduledTriggerPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug }) => <CreateDeploymentScheduledTriggerPage spaceId={spaceId} projectSlug={projectSlug}/>, {
                                            pageArea: "Project",
                                            pageId: "Project.ScheduledTriggerNew",
                                            pageName: "Add New Scheduled Trigger",
                                        }),
                                        editDeploymentScheduledTriggerPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug, triggerId }) => <EditDeploymentScheduledTriggerPage spaceId={spaceId} projectSlug={projectSlug} triggerId={triggerId}/>, {
                                            pageArea: "Project",
                                            pageId: "Project.ScheduledTrigger",
                                            pageName: "Scheduled Trigger",
                                        }),
                                        projectSettingsPage: (r) => createProjectPageRegistration(r, () => <ProjectSettingsPage />, { pageArea: "Project", pageId: "Project.Settings", pageName: "Settings" }),
                                        projectVersionControlSettingsPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug }) => <VersionControlSettingsPage spaceId={spaceId} projectSlug={projectSlug} branchName={undefined}/>, {
                                            pageArea: "Project",
                                            pageId: "Project.VersionControlSettings",
                                            pageName: "Version Control Settings",
                                        }),
                                        projectDynamicEnvironmentSettingsPage: (r) => createProjectPageRegistration(r, () => <DynamicEnvironmentSettingsPage />, { pageArea: "Project", pageId: "Project.DynamicEnvironmentSettings", pageName: "Dynamic Environment Settings" }),
                                        projectITSMProvidersControlSettingsPage: (r) => createProjectPageRegistration(r, () => <ITSMProvidersSettingsPage />, { pageArea: "Project", pageId: "Project.ITSMProvidersSettings", pageName: "ITSM Providers Settings" }),
                                        createReleaseCreationFeedTriggerPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug }) => <CreateReleaseCreationFeedTriggersPage spaceId={spaceId} projectSlug={projectSlug}/>, {
                                            pageArea: "Project",
                                            pageId: "Project.ReleaseCreationFeedTriggerNew",
                                            pageName: "Add New Release Creation Feed Trigger",
                                        }),
                                        editReleaseCreationFeedTriggerPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug, triggerId }) => <EditReleaseCreationFeedTriggersPage spaceId={spaceId} projectSlug={projectSlug} triggerId={triggerId}/>, {
                                            pageArea: "Project",
                                            pageId: "Project.ReleaseCreationFeedTrigger",
                                            pageName: "Release Creation Feed Trigger",
                                        }),
                                        createBuiltInFeedTriggerPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug }) => <CreateBuiltInFeedTriggerPage spaceId={spaceId} projectSlug={projectSlug}/>, {
                                            pageArea: "Project",
                                            pageId: "Project.BuiltInFeedTriggerNew",
                                            pageName: "Add New Built-in Feed Trigger",
                                        }),
                                        editBuiltInFeedTriggerPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug }) => <EditBuiltInFeedTriggerPage spaceId={spaceId} projectSlug={projectSlug}/>, {
                                            pageArea: "Project",
                                            pageId: "Project.BuiltInFeedTrigger",
                                            pageName: "Built-in Feed Trigger",
                                        }),
                                    },
                                    childGroups: {
                                        deployments: {
                                            partialRoute: (r) => r,
                                            pages: {
                                                channelsPage: (r) => createProjectPageRegistration(r, () => <ChannelsWithMatch />, { pageArea: "Project", pageId: "Project.Channel", pageName: "Channel" }),
                                                createChannelPage: (r) => createProjectPageRegistration(r, () => <EditChannel create={true}/>, { pageArea: "Project", pageId: "Project.ChannelNew", pageName: "Add New Channels" }),
                                                editChannelPage: (r) => createProjectPageRegistration(r, ({ channelId }) => <EditChannel create={false} channelId={channelId}/>, { pageArea: "Project", pageId: "Project.Channel", pageName: "Channel" }),
                                                deploymentsPage: (r) => createProjectPageRegistration(r, () => <DeploymentsOverview />, { pageArea: "Project", pageId: "Project.Overview", pageName: "Overview" }),
                                                releasesPage: (r) => createProjectPageRegistration(r, () => <ReleasesPage />, { pageArea: "Project", pageId: "Project.Releases", pageName: "Releases" }),
                                                createReleasePage: (r) => createProjectPageRegistration(r, () => <CreateOrEditReleasePage />, { pageArea: "Project", pageId: "Project.ReleaseNew", pageName: "Add New Release" }),
                                            },
                                            childGroups: {
                                                process: {
                                                    partialRoute: (r) => r,
                                                    childGroups: {},
                                                    pages: {
                                                        deploymentProcessPage: (r) => createProjectPageRegistration(r, () => <DeploymentProcessListPage />, { pageArea: "Project", pageId: "Project.Process", pageName: "Deployment Process" }),
                                                        deploymentProcessStepsPage: (r) => createProjectPageRegistration(r, () => <DeploymentProcessStepsPage />, { pageArea: "Project", pageId: "Project.Process", pageName: "Deployment Process" }),
                                                    },
                                                },
                                                settings: {
                                                    partialRoute: (r) => r,
                                                    childGroups: {},
                                                    pages: {
                                                        deploymentProcessSettingsPage: (r) => createProjectPageRegistration(r, () => <DeploymentProcessSettings />, { pageArea: "Project", pageId: "Project.DeploymentSettings", pageName: "Settings" }),
                                                    },
                                                },
                                                specificRelease: {
                                                    partialRoute: (r) => r,
                                                    childGroups: {},
                                                    pages: {
                                                        releasePage: (r) => createProjectPageRegistration(r, ({ releaseVersion }) => <ReleasePage releaseVersion={releaseVersion}/>, {
                                                            pageArea: "Project",
                                                            pageId: "Project.ReleaseVersion",
                                                            pageName: "Release Version",
                                                        }),
                                                        createDeploymentPage: (r) => createProjectPageRegistration(r, ({ releaseVersion }, parameters) => <CreateDeploymentPage goal={createDeploymentGoal(parameters)} releaseVersion={releaseVersion}/>, {
                                                            pageArea: "Project",
                                                            pageId: "Project.DeploymentNew",
                                                            pageName: "Add New Deployment",
                                                        }),
                                                        editReleasePage: (r) => createProjectPageRegistration(r, ({ releaseVersion }) => <CreateOrEditReleasePage releaseVersion={releaseVersion}/>, {
                                                            pageArea: "Project",
                                                            pageId: "Project.ReleaseVersionEdit",
                                                            pageName: "Edit Release Version",
                                                        }),
                                                        deploymentDetailsPage: (r) => createProjectPageRegistration(r, ({ deploymentId }) => <DeploymentDetailsPage deploymentId={deploymentId}/>, { pageArea: "Project", pageId: "Project.Deployment", pageName: "Deployment" }),
                                                    },
                                                },
                                            },
                                        },
                                        variables: {
                                            partialRoute: (r) => r,
                                            pages: {
                                                commonVariableTemplatesPage: (r) => createProjectPageRegistration(r, ({ projectSlug }) => <CommonVariableTemplates projectSlug={projectSlug}/>, {
                                                    pageArea: "Project",
                                                    pageId: "Project.VariableCommonTemplates",
                                                    pageName: "Common Variables Templates",
                                                }),
                                                projectTenantProjectTemplatesPage: (r) => createProjectPageRegistration(r, () => <ProjectTenantProjectTemplates />, { pageArea: "Project", pageId: "Project.TenantProjectTemplates", pageName: "Project Templates" }),
                                                projectTenantCommonTemplatesPage: (r) => createProjectPageRegistration(r, () => <ProjectTenantCommonTemplates />, { pageArea: "Project", pageId: "Project.TenantCommonTemplates", pageName: "Common Templates" }),
                                                libraryVariablesPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug }) => <LibraryVariableSets spaceId={spaceId} projectSlug={projectSlug}/>, {
                                                    pageArea: "Project",
                                                    pageId: "Project.VariableLibrary",
                                                    pageName: "Variables Library",
                                                }),
                                                variableTemplatesPage: (r) => createProjectPageRegistration(r, ({ projectSlug }) => <ProjectVariableTemplates projectSlug={projectSlug}/>, {
                                                    pageArea: "Project",
                                                    pageId: "Project.VariableTemplates",
                                                    pageName: "Variables Templates",
                                                }),
                                            },
                                            childGroups: {
                                                all: {
                                                    partialRoute: (r) => r,
                                                    childGroups: {},
                                                    pages: {
                                                        allVariablesPage: (r) => createProjectPageRegistration(r, () => <AllVariables />, { pageArea: "Project", pageId: "Project.VariableAll", pageName: "All Variables" }),
                                                    },
                                                },
                                                preview: {
                                                    partialRoute: (r) => r,
                                                    childGroups: {},
                                                    pages: {
                                                        variablesPreviewPage: (r) => createProjectPageRegistration(r, () => <VariablePreview />, { pageArea: "Project", pageId: "Project.VariablePreview", pageName: "Variables Preview" }),
                                                    },
                                                },
                                                wrapper: {
                                                    partialRoute: (r) => r,
                                                    childGroups: {},
                                                    pages: {
                                                        variablesPage: (r) => createProjectPageRegistration(r, () => <ProjectVariables />, { pageArea: "Project", pageId: "Project.Variables", pageName: "Variables" }),
                                                    },
                                                },
                                            },
                                        },
                                        projectRunbooks: {
                                            partialRoute: (r) => r,
                                            childGroups: {},
                                            pages: {
                                                projectRunbooksPage: (r) => createProjectPageRegistration(r, () => <RunbooksPage />, { pageArea: "Project", pageId: "Project.Operations.Runbooks", pageName: "Runbooks" }),
                                            },
                                        },
                                        specificRunbook: {
                                            partialRoute: (r) => r,
                                            childGroups: {
                                                process: {
                                                    partialRoute: (r) => r,
                                                    childGroups: {},
                                                    pages: {
                                                        projectRunbookProcessListPage: (r) => createProjectPageRegistration(r, () => <RunbookProcessListPage />, { pageArea: "Project", pageId: "Project.Runbook.Process", pageName: "Runbook Process" }),
                                                        projectRunbookProcessStepsPage: (r) => createProjectPageRegistration(r, () => <RunbookProcessStepsPage />, { pageArea: "Project", pageId: "Project.Runbook.Process", pageName: "Runbook Process" }),
                                                    },
                                                },
                                            },
                                            pages: {
                                                projectRunbookOverviewPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug, runbookId }) => <RunbookOverviewPage spaceId={spaceId} projectSlug={projectSlug} runbookId={runbookId}/>, {
                                                    pageArea: "Project",
                                                    pageId: "Project.Runbook.Overview",
                                                    pageName: "Runbook Overview",
                                                }),
                                                projectRunbookRunsListPage: (r) => createProjectPageRegistration(r, () => <RunbookRunsListPage />, { pageArea: "Project", pageId: "Project.Runbook.RunsList", pageName: "Runbook Runs List" }),
                                                projectRunbookSettingsPage: (r) => createProjectPageRegistration(r, ({ runbookId }) => <RunbookSettingsPage runbookId={runbookId}/>, { pageArea: "Project", pageId: "Project.Runbook.Settings", pageName: "Runbook Settings" }),
                                                projectRunbookSnapshotsPage: (r) => createProjectPageRegistration(r, ({ projectSlug }) => <RunbookSnapshotsPage projectSlug={projectSlug}/>, { pageArea: "Project", pageId: "Project.RunbookSnapshots", pageName: "RunbookSnapshots" }),
                                                projectRunbookSnapshotCreatePage: (r) => createProjectPageRegistration(r, ({ projectSlug }) => <RunbookSnapshotEditPage />, { pageArea: "Project", pageId: "Project.RunbookSnapshotNew", pageName: "Add New RunbookSnapshot" }),
                                                projectRunbookSnapshotEditPage: (r) => createProjectPageRegistration(r, ({ runbookSnapshotId }) => <RunbookSnapshotEditPage runbookSnapshotId={runbookSnapshotId}/>, {
                                                    pageArea: "Project",
                                                    pageId: "Project.RunbookSnapshotEdit",
                                                    pageName: "Edit RunbookSnapshot",
                                                }),
                                                projectRunbookSnapshotInfoPage: (r) => createProjectPageRegistration(r, ({ runbookSnapshotId }) => <RunbookSnapshotInfoPage runbookSnapshotId={runbookSnapshotId}/>, {
                                                    pageArea: "Project",
                                                    pageId: "Project.RunbookSnapshot",
                                                    pageName: "RunbookSnapshot",
                                                }),
                                                projectRunbookRunDetailPage: (r) => createProjectPageRegistration(r, ({ runbookRunId }) => <RunbookRunDetailsPage runbookRunId={runbookRunId}/>, { pageArea: "Project", pageId: "Project.RunbookRun", pageName: "RunbookRun" }),
                                                createRunbookRunForSnapshotPage: (r) => createProjectPageRegistration(r, ({ runbookSnapshotId }, parameters) => <CreateRunbookRunForSnapshotPage runbookSnapshotId={runbookSnapshotId} goal={createRunbookRunGoal(parameters)}/>, {
                                                    pageArea: "Project",
                                                    pageId: "Project.RunbookRunNew",
                                                    pageName: "Add New RunbookRun",
                                                }),
                                                runbookRunNowPage: (r) => createProjectPageRegistration(r, () => <RunbookRunNowPage />, { pageArea: "Project", pageId: "Project.Runbook.Run", pageName: "Runbook Run" }),
                                                runbookRunSnapshotNowPage: (r) => createProjectPageRegistration(r, ({ runbookSnapshotId }) => <RunbookRunNowPage runbookSnapshotId={runbookSnapshotId}/>, {
                                                    pageArea: "Project",
                                                    pageId: "Project.Runbook.Run",
                                                    pageName: "Runbook Run",
                                                }),
                                            },
                                        },
                                    },
                                },
                            },
                        },
                        importExports: {
                            partialRoute: (r) => r,
                            childGroups: {},
                            pages: {
                                importExportProjectTasksPage: (r) => createProjectPageRegistration(r, ({ spaceId }) => <ImportExportProjectTasksPage spaceId={spaceId}/>, { pageArea: "Project", pageId: "ImportExportTasks", pageName: "Import/Export" }),
                                importProjectsPage: (r) => createProjectPageRegistration(r, ({ spaceId }) => <ImportProjectsPage spaceId={spaceId}/>, { pageArea: "Project", pageId: "ImportProjects", pageName: "Import" }),
                                importProjectTaskPage: (r) => createProjectPageRegistration(r, ({ spaceId, taskId }) => <ImportProjectTaskPage spaceId={spaceId} taskId={taskId}/>, { pageArea: "Project", pageId: "ImportTask", pageName: "Import Task" }),
                                exportProjectsPage: (r) => createProjectPageRegistration(r, ({ spaceId }) => <ExportProjectsPage spaceId={spaceId}/>, { pageArea: "Project", pageId: "ExportProjects", pageName: "Export" }),
                                exportProjectTaskPage: (r) => createProjectPageRegistration(r, ({ spaceId, taskId }) => <ExportProjectTaskPage spaceId={spaceId} taskId={taskId}/>, { pageArea: "Project", pageId: "ExportTask", pageName: "Export Task" }),
                            },
                        },
                    },
                },
                infrastructure: {
                    partialRoute: (r) => r,
                    childGroups: {},
                    pages: {
                        infrastructureOverviewPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => <InfrastructureDashboardPage spaceId={spaceId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.Overview", pageName: "Overview" }),
                        infrastructureEnvironmentsPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => <EnvironmentsPage spaceId={spaceId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.Environments", pageName: "Environments" }),
                        infrastructureEnvironmentPage: (r) => createInfrastructurePageRegistration(r, ({ environmentId }) => <EnvironmentPage environmentId={environmentId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.Environment", pageName: "Environment" }),
                        workerPoolsPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => <WorkerPoolsPage spaceId={spaceId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.WorkerPools", pageName: "Worker Pools" }),
                        editWorkerPoolPage: (r) => createInfrastructurePageRegistration(r, ({ workerPoolId }) => <WorkerPoolPage workerPoolId={workerPoolId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.WorkerPool", pageName: "Worker Pool" }),
                        deploymentTargetsPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => <DeploymentTargetsPage spaceId={spaceId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargets", pageName: "Deployment Targets" }),
                        newDeploymentTargetPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => (<InfrastructureLayout>
                                        <MachineTargetNewPage spaceId={spaceId} environmentId={undefined}/>
                                    </InfrastructureLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetsSelect", pageName: "Add New Deployment Target Selection" }, [
                            "Windows",
                            "Linux",
                            "Mac",
                            "Azure",
                            "Listening Tentacle",
                            "Polling Tentacle",
                            "SSH Connection",
                            "Offline Package Drop",
                            "Azure Web App",
                            "Azure Cloud Service",
                            "Service Fabric Cluster",
                            "Kubernetes (K8s) Cluster",
                            "Cloud Region",
                            "Step Package",
                        ]),
                        newDeploymentTargetWithEnvironmentPage: (r) => createInfrastructurePageRegistration(r, ({ environmentId, spaceId }) => (<InfrastructureLayout>
                                        <MachineTargetNewPage spaceId={spaceId} environmentId={environmentId}/>
                                    </InfrastructureLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetsSelect", pageName: "Add New Deployment Target Selection" }),
                        createDeploymentTargetPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => (<InfrastructureLayout>
                                        <DeploymentTargetSettingsPage spaceId={spaceId}/>
                                    </InfrastructureLayout>), {
                            pageArea: "Infrastructure",
                            pageId: "Infrastructure.DeploymentTargetsNew",
                            pageName: "Add New Deployment Target",
                        }),
                        deploymentTargetConnectionPage: (r) => createInfrastructurePageRegistration(r, ({ machineId }) => (<MachineLayout>
                                        <DeploymentTargetConnectionsPage machineId={machineId}/>
                                    </MachineLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetConnectivity", pageName: "Deployment Target Connectivity" }),
                        deploymentTargetSettingsPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId, machineId }) => (<MachineLayout>
                                        <DeploymentTargetSettingsPage spaceId={spaceId} machineId={machineId}/>
                                    </MachineLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetSettings", pageName: "Deployment Target Settings" }),
                        deploymentTargetDeploymentsPage: (r) => createInfrastructurePageRegistration(r, ({ machineId }) => (<MachineLayout>
                                        <MachineDeploymentsPage machineId={machineId}/>
                                    </MachineLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetDeployments", pageName: "Deployment Target Deployments" }),
                        deploymentTargetRunbookRunsPage: (r) => createInfrastructurePageRegistration(r, ({ machineId }) => (<MachineLayout>
                                        <MachineRunbookRunsPage machineId={machineId}/>
                                    </MachineLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetRunbookRuns", pageName: "Deployment Target Runbook Runs" }),
                        deploymentTargetEventsPage: (r) => createInfrastructurePageRegistration(r, ({ machineId }) => (<MachineLayout>
                                        <MachineEventsPage machineId={machineId}/>
                                    </MachineLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetEvents", pageName: "Deployment Target Events" }),
                        discoverMachinePage: (r) => createInfrastructurePageRegistration(r, ({ spaceId, endpointKey }) => (<InfrastructureLayout>
                                        <DeploymentTargetDiscoveryPage spaceId={spaceId} endpointKey={endpointKey}/>
                                    </InfrastructureLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetsDiscover", pageName: "Discover Deployment Target" }),
                        discoverMachineWithEnvironmentPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId, endpointKey, environmentId }) => (<InfrastructureLayout>
                                        <DeploymentTargetDiscoveryPage spaceId={spaceId} endpointKey={endpointKey} environmentId={environmentId}/>
                                    </InfrastructureLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetsDiscover", pageName: "Discover Deployment Target" }),
                        createWorkerMachinePage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => (<InfrastructureLayout>
                                        <WorkerMachineSettingsPage spaceId={spaceId}/>
                                    </InfrastructureLayout>), {
                            pageArea: "Infrastructure",
                            pageId: "Infrastructure.WorkersNew",
                            pageName: "Add New Worker",
                        }),
                        workerMachinesPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => <WorkerMachinesPage spaceId={spaceId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.Workers", pageName: "Workers" }),
                        newWorkerMachinePage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => (<InfrastructureLayout>
                                        <WorkerMachineNewPage spaceId={spaceId} workerPoolId={undefined}/>
                                    </InfrastructureLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.WorkersSelect", pageName: "Add New Worker Selection" }, ["Windows", "Linux", "Mac", "Listening Tentacle", "Polling Tentacle", "SSH Connection"]),
                        newWorkerMachineWithWorkerPoolPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId, workerPoolId }) => (<InfrastructureLayout>
                                        <WorkerMachineNewPage spaceId={spaceId} workerPoolId={workerPoolId}/>
                                    </InfrastructureLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.WorkersSelect", pageName: "Add New Worker Selection" }),
                        discoverWorkerMachinePage: (r) => createInfrastructurePageRegistration(r, ({ spaceId, endpointKey }) => (<InfrastructureLayout>
                                        <WorkerMachineDiscoveryPage spaceId={spaceId} endpointKey={endpointKey}/>
                                    </InfrastructureLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.WorkersDiscover", pageName: "Discover Worker" }),
                        discoverWorkerMachineWithWorkerPoolPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId, workerPoolId, endpointKey }) => (<InfrastructureLayout>
                                        <WorkerMachineDiscoveryPage spaceId={spaceId} workerPoolId={workerPoolId} endpointKey={endpointKey}/>
                                    </InfrastructureLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.WorkersDiscover", pageName: "Discover Worker" }),
                        workerMachineConnectionPage: (r) => createInfrastructurePageRegistration(r, ({ machineId }) => (<WorkerMachineLayout>
                                        <WorkerMachineConnectionsPage machineId={machineId}/>
                                    </WorkerMachineLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.WorkerConnectivity", pageName: "Worker Connectivity" }),
                        workerMachineSettingsPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId, machineId }) => (<WorkerMachineLayout>
                                        <WorkerMachineSettingsPage spaceId={spaceId} machineId={machineId}/>
                                    </WorkerMachineLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.WorkerSettings", pageName: "Worker Settings" }),
                        workerMachineEventsPage: (r) => createInfrastructurePageRegistration(r, ({ machineId }) => (<WorkerMachineLayout>
                                        <MachineEventsPage machineId={machineId}/>
                                    </WorkerMachineLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.WorkerEvents", pageName: "Worker Events" }),
                        machinePoliciesPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => <MachinePoliciesPage spaceId={spaceId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.MachinePolicies", pageName: "Machine Policies" }),
                        editMachinePolicyPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId, machinePolicyId }) => <MachinePolicyPage spaceId={spaceId} machinePolicyId={machinePolicyId}/>, {
                            pageArea: "Infrastructure",
                            pageId: "Infrastructure.MachinePolicy",
                            pageName: "Machine Policy",
                        }),
                        createMachinePolicyPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => <MachinePolicyPage spaceId={spaceId} create={true}/>, {
                            pageArea: "Infrastructure",
                            pageId: "Infrastructure.MachinePoliciesNew",
                            pageName: "Add New Machine Policy",
                        }),
                        proxiesPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => <ProxiesPage spaceId={spaceId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.Proxies", pageName: "Proxies" }),
                        editProxyPage: (r) => createInfrastructurePageRegistration(r, ({ proxyId }) => <ProxyPage proxyId={proxyId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.Proxy", pageName: "Proxy" }),
                        createProxyPage: (r) => createInfrastructurePageRegistration(r, () => <ProxyPage create={true}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.ProxiesNew", pageName: "Add New Proxy" }),
                        infrastructureAccountsPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => <AccountsPage spaceId={spaceId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.Accounts", pageName: "Accounts" }),
                        editInfrastructureAccountPage: (r) => createInfrastructurePageRegistration(r, ({ accountId }) => <AccountPage createNew={false} accountId={accountId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.Account", pageName: "Account" }),
                        createInfrastructureAccountPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }, queryParams) => {
                            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                            const accountType = AccountType[queryParams.accountType as keyof typeof AccountType];
                            return <AccountPage createNew={true} accountType={accountType}/>;
                        }, { pageArea: "Infrastructure", pageId: "Infrastructure.AccountsNew", pageName: "Add New Account" }),
                        dynamicEnvironmentsPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => <DynamicEnvironmentsPage spaceId={spaceId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.DynamicEnvironments", pageName: "Dynamic Environments" }),
                        dynamicEnvironmentPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId, environmentId }) => <DynamicEnvironmentPage spaceId={spaceId} environmentId={environmentId}/>, {
                            pageArea: "Infrastructure",
                            pageId: "Infrastructure.DynamicEnvironment",
                            pageName: "Dynamic Environment",
                        }),
                    },
                },
                tenants: {
                    partialRoute: (r) => r,
                    childGroups: {
                        specificTenant: {
                            partialRoute: (r) => r,
                            pages: {
                                tenantOverviewPage: (r) => createTenantPageRegistration(r, ({ tenantId }) => <TenantOverviewPage tenantId={tenantId}/>, { pageArea: "Tenants", pageId: "Tenant.Overview", pageName: "Tenant Overview" }),
                                tenantVariablesPage: (r) => createTenantPageRegistration(r, ({ tenantId }) => <TenantVariablesPage tenantId={tenantId}/>, { pageArea: "Tenants", pageId: "Tenant.Variables", pageName: "Tenant Variables" }),
                                tenantSettingsPage: (r) => createTenantPageRegistration(r, ({ tenantId }) => <TenantSettingsPage tenantId={tenantId}/>, { pageArea: "Tenants", pageId: "Tenant.Settings", pageName: "Tenant Settings" }),
                                tenantTasksPage: (r) => createTenantPageRegistration(r, ({ tenantId }) => <TenantTasksPage tenantId={tenantId}/>, { pageArea: "Tenants", pageId: "Tenant.Tasks", pageName: "Tenant Tasks" }),
                            },
                            childGroups: {},
                        },
                    },
                    pages: {
                        tenantsPage: (r) => createTenantPageRegistration(r, ({ spaceId }) => <TenantsPage spaceId={spaceId}/>, { pageArea: "Tenants", pageId: "Tenants", pageName: "Tenants" }),
                    },
                },
                library: {
                    partialRoute: (r) => r,
                    childGroups: {},
                    pages: {
                        builtInRepositoryPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <BuiltInRepositoryPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.BuiltInRepository", pageName: "Packages" }),
                        packageDetailsPage: (r) => createLibraryPageRegistration(r, ({ spaceId, id }) => <PackageDetailsPage spaceId={spaceId} packageId={id}/>, { pageArea: "Library", pageId: "Library.BuiltInRepositoryPackage", pageName: "Package Details" }),
                        packageVersionsListPage: (r) => createLibraryPageRegistration(r, ({ spaceId, packageId }) => <PackageVersionsListPage spaceId={spaceId} packageId={packageId}/>, {
                            pageArea: "Library",
                            pageId: "Library.BuiltInRepositoryPackageVersions",
                            pageName: "Package Versions",
                        }),
                        buildInformationOverviewPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <BuildInformationOverviewPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.BuildInformationRepository", pageName: "Build Information" }),
                        buildInformationVersionsPage: (r) => createLibraryPageRegistration(r, ({ spaceId, packageId }) => <BuildInformationVersionsPage spaceId={spaceId} packageId={packageId}/>, {
                            pageArea: "Library",
                            pageId: "Library.BuildInformationRepositoryPackageVersions",
                            pageName: "Build Information Versions",
                        }),
                        buildInformationDetailPage: (r) => createLibraryPageRegistration(r, ({ spaceId, id }) => <BuildInformationDetailPage spaceId={spaceId} id={id}/>, {
                            pageArea: "Library",
                            pageId: "Library.BuildInformationRepositoryPackage",
                            pageName: "Build Information Details",
                        }),
                        feedsPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <ExternalFeedsPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.Feeds", pageName: "External Feeds" }),
                        createFeedPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <ExternalFeedPage spaceId={spaceId} create={true}/>, { pageArea: "Library", pageId: "Library.FeedsNew", pageName: "Add New External Feed" }),
                        editFeedPage: (r) => createLibraryPageRegistration(r, ({ spaceId, feedId }) => <ExternalFeedPage spaceId={spaceId} feedId={feedId}/>, { pageArea: "Library", pageId: "Library.Feed", pageName: "External Feed" }),
                        testFeedPage: (r) => createLibraryPageRegistration(r, ({ spaceId, feedId }) => <ExternalFeedTestPage spaceId={spaceId} feedId={feedId}/>, { pageArea: "Library", pageId: "Library.FeedTest", pageName: "Test External Feed" }),
                        lifecyclesPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <LifecyclesPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.Lifecycles", pageName: "Lifecycles" }),
                        createLifecyclePage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <LifecyclePage spaceId={spaceId} create={true}/>, { pageArea: "Library", pageId: "Library.LifecyclesNew", pageName: "Add New Lifecycle" }),
                        editLifecyclePage: (r) => createLibraryPageRegistration(r, ({ spaceId, lifecycleId }) => <LifecyclePage spaceId={spaceId} lifecycleId={lifecycleId}/>, { pageArea: "Library", pageId: "Library.Lifecycle", pageName: "Lifecycle" }),
                        scriptModulesPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <ScriptModulesPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.ScriptModules", pageName: "Script Modules" }),
                        createScriptModulePage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <ScriptModulePage spaceId={spaceId} create={true}/>, { pageArea: "Library", pageId: "Library.ScriptModulesNew", pageName: "Add New Script Module" }),
                        editScriptModulePage: (r) => createLibraryPageRegistration(r, ({ spaceId, scriptModuleId }) => <ScriptModulePage spaceId={spaceId} scriptModuleId={scriptModuleId}/>, { pageArea: "Library", pageId: "Library.ScriptModule", pageName: "Script Module" }),
                        stepTemplatesPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <ActionTemplatesPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.StepTemplates", pageName: "Library Step Templates" }),
                        builtInStepTemplatesPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <BuiltinTemplatesPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.StepTemplatesBuiltIn", pageName: "Built-in Step Templates" }),
                        communityStepTemplatesPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <CommunityTemplatesPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.StepTemplatesCommunity", pageName: "Community Step Templates" }),
                        communityStepTemplatePage: (r) => createLibraryPageRegistration(r, ({ spaceId, templateId }) => <CommunityTemplatePage spaceId={spaceId} templateId={templateId}/>, {
                            pageArea: "Library",
                            pageId: "Library.StepTemplatesCommunityTemplate",
                            pageName: "Community Step Template",
                        }),
                        createStepTemplatePage: (r) => createLibraryPageRegistration(r, ({ spaceId, actionType }) => (<ActionTemplateLayout>
                                        <ActionTemplatePage spaceId={spaceId} actionType={actionType}/>
                                    </ActionTemplateLayout>), { pageArea: "Library", pageId: "Library.StepTemplateNew", pageName: "Add New Step Template" }),
                        editStepTemplatePage: (r) => createLibraryPageRegistration(r, ({ spaceId, templateId }) => (<ActionTemplateLayout>
                                        <ActionTemplatePage spaceId={spaceId} templateId={templateId}/>
                                    </ActionTemplateLayout>), { pageArea: "Library", pageId: "Library.StepTemplate", pageName: "Step Template" }),
                        runStepTemplatePage: (r) => createLibraryPageRegistration(r, ({ spaceId, templateId }) => <RunActionTemplatePage spaceId={spaceId} templateId={templateId}/>, { pageArea: "Library", pageId: "Library.StepTemplateRun", pageName: "Run Step Template" }),
                        stepTemplateUsagePage: (r) => createLibraryPageRegistration(r, ({ spaceId, templateId }) => (<ActionTemplateLayout>
                                        <ActionTemplateUsagePage spaceId={spaceId} templateId={templateId}/>
                                    </ActionTemplateLayout>), { pageArea: "Library", pageId: "Library.StepTemplateUsage", pageName: "Step Template Usage" }),
                        archiveCertificatePage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <ArchivedCertificatesPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.CertificatesArchive", pageName: "Certificates Archive" }),
                        tagSetsPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <TagSetsPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.TenantTagSets", pageName: "Tenant Tag Sets" }),
                        createTagSetPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <TagSetPage spaceId={spaceId} create={true}/>, { pageArea: "Library", pageId: "Library.TenantTagSetsNew", pageName: "Add New Tenant Tag Set" }),
                        editTagSetPage: (r) => createLibraryPageRegistration(r, ({ spaceId, tagSetId }) => <TagSetPage spaceId={spaceId} tagSetId={tagSetId}/>, { pageArea: "Library", pageId: "Library.TenantTagSet", pageName: "Tenant Tag Set" }),
                        variableSetsPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <VariableSetsPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.VariableSets", pageName: "Variable Sets" }),
                        editVariableSetPage: (r) => createLibraryPageRegistration(r, ({ spaceId, variableSetId }) => <EditVariableSetPage spaceId={spaceId} variableSetId={variableSetId}/>, { pageArea: "Library", pageId: "Library.VariableSet", pageName: "Variable Set" }),
                        gitCredentialsPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <GitCredentialsPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.GitCredentials", pageName: "Git Credentials" }),
                        createGitCredentialPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <GitCredentialPage spaceId={spaceId} create={true}/>, { pageArea: "Library", pageId: "Library.GitCredentialsNew", pageName: "Add New Git Credential" }),
                        editGitCredentialPage: (r) => createLibraryPageRegistration(r, ({ spaceId, gitCredentialId }) => <GitCredentialPage spaceId={spaceId} gitCredentialId={gitCredentialId}/>, {
                            pageArea: "Library",
                            pageId: "Library.GitCredential",
                            pageName: "Git Credential",
                        }),
                        gitConnectionsPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <GitConnectionsPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.GitConnections", pageName: "GitHub Connections" }),
                        createGitHubConnectionPage: (r) => createLibraryPageRegistration(r, ({ spaceId, installationId }) => <AddGitHubConnectionPage spaceId={spaceId} installationId={installationId}/>, {
                            pageArea: "Library",
                            pageId: "Library.GitConnectionsAddGitHub",
                            pageName: "Add GitHub Connection",
                        }),
                        editGitHubConnectionPage: (r) => createLibraryPageRegistration(r, ({ spaceId, connectionId }) => <GitHubConnectionPage spaceId={spaceId} connectionId={connectionId}/>, {
                            pageArea: "Library",
                            pageId: "Library.GitConnectionsGetGitHub",
                            pageName: "GitHub Connection",
                        }),
                        createCertificatePage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <CertificatePage spaceId={spaceId} create={true}/>, { pageArea: "Library", pageId: "Library.CertificatesNew", pageName: "Add New Certificate" }),
                        editCertificatePage: (r) => createLibraryPageRegistration(r, ({ spaceId, certificateId }) => <CertificatePage spaceId={spaceId} create={false} certificateId={certificateId}/>, {
                            pageArea: "Library",
                            pageId: "Library.Certificate",
                            pageName: "Certificate",
                        }),
                        certificatesPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <CertificatesPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.Certificates", pageName: "Certificates" }),
                    },
                },
                insights: {
                    partialRoute: (r) => r,
                    pages: {
                        insightsUpsellPage: (r) => createInsightsPageRegistration(r, ({ spaceId }) => <ReportUpsell />, { pageArea: "Insights", pageId: "Insights.Upsell", pageName: "Insights Reports Upsell" }),
                    },
                    childGroups: {
                        reports: {
                            partialRoute: (r) => r,
                            pages: {
                                insightReportListPage: (r) => createInsightsPageRegistration(r, ({ spaceId }) => <InsightsReportListPage spaceId={spaceId}/>, { pageArea: "Insights", pageId: "Insights.Reports", pageName: "Insights Reports" }),
                            },
                            childGroups: {
                                specificReport: {
                                    partialRoute: (p) => p,
                                    childGroups: {},
                                    pages: {
                                        insightsReportOverviewPage: (r) => createInsightsReportPageRegistration(r, (report) => <InsightsReportOverviewPage report={report}/>, { pageArea: "Insights", pageId: "Insights.Report.Overview", pageName: "Insights Report Overview" }),
                                        insightsReportDeploymentFrequencyPage: (r) => createInsightsReportPageRegistration(r, (report) => <InsightsReportDeploymentFrequencyPage report={report}/>, {
                                            pageArea: "Insights",
                                            pageId: "Insights.Report.DeploymentFrequency",
                                            pageName: "Insights Report Deployment Frequency",
                                        }),
                                        insightsReportDeploymentLeadTimePage: (r) => createInsightsReportPageRegistration(r, (report) => <InsightsReportDeploymentLeadTimePage report={report}/>, {
                                            pageArea: "Insights",
                                            pageId: "Insights.Report.DeploymentLeadTime",
                                            pageName: "Insights Report Deployment Lead Time",
                                        }),
                                        insightsReportDeploymentFailureRatePage: (r) => createInsightsReportPageRegistration(r, (report) => <InsightsReportDeploymentFailureRatePage report={report}/>, {
                                            pageArea: "Insights",
                                            pageId: "Insights.Report.DeploymentFailureRate",
                                            pageName: "Insights Report Failure Rate",
                                        }),
                                        insightsReportDeploymentMeanTimeToRecoveryPage: (r) => createInsightsReportPageRegistration(r, (report) => <InsightsReportDeploymentMeanTimeToRecoveryPage report={report}/>, {
                                            pageArea: "Insights",
                                            pageId: "Insights.Report.MeanTimeToRecovery",
                                            pageName: "Insights Report Mean Time to Recovery",
                                        }),
                                        insightsReportDeploymentDurationPage: (r) => createInsightsReportPageRegistration(r, (report) => <InsightsReportDeploymentDurationPage report={report}/>, {
                                            pageArea: "Insights",
                                            pageId: "Insights.Report.DeploymentDuration",
                                            pageName: "Insights Report Deployment Duration",
                                        }),
                                        insightsReportSettingsPage: (r) => createInsightsReportPageRegistration(r, (report, refreshReport) => <InsightsReportSettingsPage report={report} refreshReport={refreshReport}/>, {
                                            pageArea: "Insights",
                                            pageId: "Insights.Report.Settings",
                                            pageName: "Insights Report Settings",
                                        }),
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    },
});
export const allPagesFlattened = getPagesFromPageMap(allPages);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getPagesFromPageMap<ChildGroups extends Record<string, PageGroup<any, any, any>>, PageMap extends PageGroup<any, ChildGroups, any>>(pageMap: PageMap): UnknownPageRegistration[] {
    const childGroupPages = Object.values(pageMap.childGroups).flatMap((childGroup) => getPagesFromPageMap(childGroup));
    const entries: UnknownPageRegistration[] = Object.values(pageMap.pages);
    return [...entries, ...childGroupPages];
}
function generateInsightsReportFilterQueryString(reportFilter: ReportFilter) {
    return generateQueryString([insightsSplitParam, insightsCadenceParam, insightsTenantTagSetIdParam], reportFilter);
}
function generateProjectInsightsReportFilterQueryString(filter: ProjectInsightsFilterQueryParams) {
    return generateQueryString([projectInsightsChannelIdParam, projectInsightsEnvironmentIdParam, insightsCadenceParam, projectInsightsTenantIdParam], filter);
}
function createRunbookRunGoal({ previousRunbookRunId, ...rest }: {
    environmentIds?: string[];
    previousRunbookRunId?: string;
}): RunGoal {
    if (previousRunbookRunId !== undefined) {
        return { previousRunbookRunId };
    }
    return { ...rest };
}
function createDeploymentGoal({ previousDeploymentId, ...rest }: {
    environmentIds?: string[];
    previousDeploymentId?: string;
    tenantIds?: string[];
    tenantTags?: string[];
}): CreateDeploymentGoal {
    if (previousDeploymentId !== undefined) {
        return { previousDeploymentId };
    }
    return { ...rest };
}
