import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
export default function FeedbackExternalLink() {
    if (new Date() > new Date("2024-05-01")) {
        return null;
    }
    return (<div>
            We'd love to <ExternalLink href={"tenant-variables-feedback"}>hear about your experience</ExternalLink>.
        </div>);
}
