/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { EditIcon, Tooltip } from "@octopusdeploy/design-system-components";
import type { DashboardItemResource, TagSetResource } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import { sortBy } from "lodash";
import * as React from "react";
import FrozenIcon from "~/areas/projects/components/ProjectDashboard/frozen-icon.svg";
import { repository } from "~/clientInstance";
import Logo from "../../../../components/Logo/Logo";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";
import Tag from "../../../../components/Tag/Tag";
import type { DataCube, DeploymentOverviewFilters } from "./DataCube";
import { DimensionTypes, DimensionGetters } from "./DataCube";
import TenantsMissingVariablesNotifier from "./TenantsMissingVariablesNotifier";
import styles from "./style.module.less";
import untenantedDeploymentLogo from "./un-tenanted-deployment-logo.svg";
interface DataSet {
    matrix: Matrix;
    columnDimension: DimensionTypes;
    groupDimension: DimensionTypes;
    rowDimension: DimensionTypes;
    getGroups(): string[];
    groupTitle(groupId: string, showEditLink?: boolean): React.ReactNode;
    getRowsForGroup(groupId: string, take?: number): string[];
    rowTitle(rowId: string | null): React.ReactNode;
    getColumnsForGroup(groupId: string): string[];
    columnTitle(columnId: string): string;
    rowLabel(): string;
}
interface Matrix {
    [groupId: string]: {
        [rowId: string]: {
            [columnId: string]: DashboardItemResource[];
        };
    };
}
function getDataSet(filter: DeploymentOverviewFilters, self: DataCube): DataSet {
    const rowDimension = filter.rowDimension || DimensionTypes.None;
    const columnDimension = filter.columnDimension || DimensionTypes.None;
    const groupDimension = filter.groupBy || DimensionTypes.None;
    let deployments = self.deployments;
    if (filter[DimensionTypes.Release]) {
        //To make the rest of the process quicker, filter releases (probably could filter any & all)
        deployments = deployments.filter((i) => {
            return filter[DimensionTypes.Release][DimensionGetters[DimensionTypes.Release](i)];
        });
    }
    const groupedTagSet: TagSetResource = groupDimension === DimensionTypes.TagSet ? self.tagSetIndex[filter.groupByExtra!] : null!;
    const matrix = enterTheMatrix(deployments, getGroupGetter(groupDimension, self), rowDimension, columnDimension);
    return {
        matrix,
        getGroups: () => {
            let groups: Array<string | null> = [null];
            if (groupDimension === DimensionTypes.TagSet) {
                groups = ([null] as Array<string | null>).concat(sortBy(groupedTagSet.Tags, (t) => t.SortOrder).map((t) => t.CanonicalTagName));
            }
            else if (groupDimension === DimensionTypes.ProjectGroup) {
                groups = sortBy(self.projectGroupIndex, (g) => g.Name).map((g) => g.Id);
            }
            else if (filter.groupBy === DimensionTypes.Channel) {
                groups = Object.keys(self.channelIndex)
                    .map((c) => self.channelIndex[c])
                    .sort((channelA, channelB) => {
                    return (
                    // Sort by default channel first
                    // Then sort by name alphabetically
                    (channelA.IsDefault === channelB.IsDefault ? 0 : channelA.IsDefault ? -1 : 1) || (channelA.Name.toLowerCase() === channelB.Name.toLowerCase() ? 0 : channelA.Name.toLowerCase() < channelB.Name.toLowerCase() ? -1 : 1));
                })
                    .map((channel) => channel.Id);
            }
            if (filter.groupBy === DimensionTypes.TagSet) {
                // If we are filtering by the same tag set that we are grouping by,
                // then all tenants within an excluded group will be filtered out in `getRowsForGroup`
                // But we still want to exclude tenants that don't have any tags from the tag set we are grouping by (i.e. the `null` group)
                return groups.filter((g) => g !== null) as string[];
            }
            else if (filter[filter.groupBy!]) {
                return groups.filter((groupId) => filter[filter.groupBy!][groupId!]) as string[];
            }
            return groups as string[];
        },
        groupTitle: (groupId, showEditLink) => {
            if (!groupDimension || !groupId) {
                return null;
            }
            if (groupDimension === DimensionTypes.Channel) {
                if (Object.keys(self.channelIndex).length < 2) {
                    return null;
                }
                return <div>Channel: {self.channelIndex[groupId].Name}</div>;
            }
            else if (groupDimension === DimensionTypes.TagSet) {
                const tag = groupedTagSet.Tags.find((t) => t.CanonicalTagName === groupId);
                return (<div>
                        {groupedTagSet.Name}: <Tag tagName={tag!.Name} description={tag!.Description} tagColor={tag!.Color} showTooltip={false}/>
                    </div>);
            }
            else if (groupDimension === DimensionTypes.ProjectGroup) {
                const groupName = self.projectGroupIndex[groupId].Name;
                return showEditLink ? (<div className={styles.projectGroupHeader}>
                        {groupName}
                        <InternalLink to={links.editProjectGroupPage.generateUrl({ spaceId: repository.spaceId!, projectGroupId: groupId })}>
                            <EditIcon />
                        </InternalLink>
                    </div>) : (groupName);
            }
            else if (filter.groupBy === DimensionTypes.Channel) {
                if (Object.keys(self.channelIndex).length < 2) {
                    return null;
                }
                return <div>Channel: {self.channelIndex[groupId].Name}</div>;
            }
            throw new Error("Only Channel Grouping Supported");
        },
        getRowsForGroup: (groupId: string, take?: number) => {
            let rows: Array<string | null> = [];
            if (rowDimension === DimensionTypes.Tenant) {
                rows = replaceUntenantedIdWithNull(self, Object.keys(self.tenantIndex));
                if (groupDimension === DimensionTypes.TagSet) {
                    rows = rows.filter((tenantId) => {
                        const tenant = self.tenantIndex[tenantId!];
                        if (groupId === null) {
                            return !tenant || !groupedTagSet.Tags.find((t) => tenant.TenantTags.indexOf(t.CanonicalTagName) !== -1);
                        }
                        else {
                            return tenant && tenant.TenantTags.indexOf(groupId) !== -1;
                        }
                    });
                }
            }
            else if (rowDimension === DimensionTypes.Project) {
                rows = Object.keys(self.projectIndex);
                if (groupDimension === DimensionTypes.ProjectGroup) {
                    rows = rows.filter((projectId) => self.projectIndex[projectId!].ProjectGroupId === groupId);
                    if (filter[DimensionTypes.ProjectName]) {
                        const filterName = Object.keys(filter[DimensionTypes.ProjectName])[0];
                        rows = rows.filter((projectId) => self.projectIndex[projectId!].Name.toLocaleUpperCase().includes(filterName.toLocaleUpperCase()));
                    }
                    if (take) {
                        rows = limitProjects(rows, take);
                    }
                }
            }
            else if (rowDimension === DimensionTypes.Release) {
                rows = filter.groupBy === DimensionTypes.Channel ? Object.keys(self.releaseIndex).filter((r) => self.releaseIndex[r].ChannelId === groupId) : Object.keys(self.releaseIndex);
            }
            return rows as string[];
        },
        rowTitle: (rowId: string | null) => {
            if (rowDimension === DimensionTypes.Tenant) {
                if (rowId === null) {
                    return tile(untenantedDeploymentLogo, "Untenanted");
                }
                else {
                    const tenant = self.tenantIndex[rowId];
                    return (<div className={styles.rowHeader}>
                            {tile(tenant.Links.Logo, tenant.Name, links.tenantOverviewPage.generateUrl({ spaceId: repository.spaceId!, tenantId: rowId }))}
                            <TenantsMissingVariablesNotifier rowId={rowId} missingVariableTenantsPromise={self.missingVariableTenantsPromise}/>
                        </div>);
                }
            }
            if (rowId === null) {
                throw Error("A project or release has a null ID which should never happen");
            }
            if (rowDimension === DimensionTypes.Project) {
                const project = self.projectIndex[rowId];
                return tile(project.Links.Logo, project.Name, links.projectRootRedirect.generateUrl({ spaceId: repository.spaceId!, projectSlug: project.Slug }), project.IsDisabled, project.IsFrozen);
            }
            else if (rowDimension === DimensionTypes.Release) {
                const release = self.releaseIndex[rowId];
                return (<div className={styles.rowCell}>
                        <div className={styles.rowHeader}>
                            <InternalLink to={links.releasePage.generateUrl({ spaceId: release.SpaceId, projectSlug: self.projectIndex[release.ProjectId].Slug, releaseVersion: release.Version })}>{release.Version}</InternalLink>
                        </div>
                        {self.blockedReleases.indexOf(rowId) !== -1 && (<div className={styles.blockAlert}>
                                <Tooltip content="This release has been blocked from future deployments. View the release details for more information.">
                                    <em className={cn("fa-solid fa-exclamation-triangle", styles.blockAlertIcon)}/>
                                </Tooltip>
                            </div>)}
                    </div>);
            }
        },
        getColumnsForGroup: (groupId: string) => {
            let colms = Object.keys(self.environmentIndex);
            if (groupDimension === DimensionTypes.Channel) {
                colms = self.channelEnvironments[groupId].filter((environmentId) => colms.includes(environmentId));
            }
            else if (groupDimension === DimensionTypes.ProjectGroup) {
                const projectEnvironments = Object.keys(self.projectIndex)
                    .map((p) => self.projectIndex[p])
                    .filter((p) => p.ProjectGroupId === groupId)
                    .reduce<string[]>((arr, p) => arr.concat(p.EnvironmentIds), []);
                colms = colms.filter((environmentId) => projectEnvironments.indexOf(environmentId) !== -1);
            }
            if (filter[columnDimension]) {
                return colms.filter((rowId) => filter[columnDimension][rowId]);
            }
            return colms;
        },
        columnTitle: (columnId) => {
            //Currently only supports environments for column
            return self.environmentIndex[columnId].Name;
        },
        rowLabel: () => {
            switch (rowDimension) {
                case DimensionTypes.Tenant:
                    return "Tenant";
                case DimensionTypes.Release:
                    return "Release";
                default:
                    return "";
            }
        },
        groupDimension,
        rowDimension,
        columnDimension,
    };
}
function getGroupGetter(groupDimension: DimensionTypes, cube: DataCube) {
    if (groupDimension === DimensionTypes.TagSet) {
        // Tag sets need special getter since they aren't stored on the deployment item itself
        return (item: DashboardItemResource) => (item.TenantId ? cube.tenantTagIndex[item.TenantId] || [] : []);
        //groupedTagSet = cube.tagSetIndex[filter.groupByExtra];
    }
    else if (groupDimension === DimensionTypes.ProjectGroup) {
        // Project groups need special getter since they aren't stored on the deployment item itself
        return (item: DashboardItemResource) => {
            const project = cube.projectIndex[item.ProjectId]; //the project won't be here if we've filterd due to project limit
            return project ? project.ProjectGroupId : null;
        };
    }
    return DimensionGetters[groupDimension];
}
function replaceUntenantedIdWithNull(self: DataCube, rows: (string | null)[]) {
    // The Untenanted tenant starts with an undefined id as null ids are not returned as part of the response, so the expected null value is re-instated here
    return self.tenantIndex["undefined"] ? rows.map((id) => (id === "undefined" ? null : id)) : rows;
}
function tile(logoUrl: string, name: string, toUrl?: LinkHref, isDisabled?: boolean, isFrozen?: boolean) {
    // These need to be in separate spans (otherwise lots of text smooshes the image, aka. main dashboard).
    const frozenIcon = (<span>
            <img style={{ padding: "5px" }} src={FrozenIcon} alt={"Frozen Environment Icon"}/>
            {/*TODO: tool tip*/}
        </span>);
    if (toUrl) {
        return (<InternalLink to={toUrl} className={cn(styles.rowHeader, isDisabled ? styles.disabled : null)}>
                <span>
                    <Logo url={logoUrl} size="2.25rem" isDisabled={isDisabled}/>
                </span>
                <span style={{ paddingLeft: "0.5rem" }} title={isDisabled ? "Disabled" : ""}>
                    {name}
                </span>
                {isFrozen ? frozenIcon : <></>}
            </InternalLink>);
    }
    else {
        return (<div className={cn(styles.rowHeader, isDisabled ? styles.disabled : null)}>
                <span>
                    <Logo url={logoUrl} size="2.25rem" isDisabled={isDisabled}/>
                </span>
                <span style={{ paddingLeft: "0.25rem" }} title={isDisabled ? "Disabled" : ""}>
                    {name}
                </span>
                {isFrozen ? frozenIcon : <></>}
            </div>);
    }
}
type GroupingFunction = ((item: DashboardItemResource) => string[]) | ((item: DashboardItemResource) => string | null);
function enterTheMatrix(deployments: DashboardItemResource[], groupByFunction: GroupingFunction, rowDimension: DimensionTypes, columnDimension: DimensionTypes): Matrix {
    const rowFromTask = DimensionGetters[rowDimension];
    const columnFromTask = DimensionGetters[columnDimension];
    const matrix: Matrix = {};
    deployments.forEach((deploymentTask: DashboardItemResource) => {
        let groupingIds = groupByFunction(deploymentTask);
        if (!Array.isArray(groupingIds)) {
            groupingIds = [groupingIds!];
        }
        if (groupingIds.length === 0) {
            groupingIds = [null!];
        }
        groupingIds.forEach((groupingId) => {
            let group = matrix[groupingId];
            if (!group) {
                group = matrix[groupingId] = {};
            }
            const rowId = rowFromTask(deploymentTask);
            let row = group[rowId];
            if (!row) {
                row = group[rowId] = {};
            }
            const columnId = columnFromTask(deploymentTask);
            if (!row[columnId]) {
                row[columnId] = [];
            }
            row[columnId].push(deploymentTask);
        });
    });
    Object.keys(matrix).forEach((groupId) => Object.keys(matrix[groupId]).forEach((rowId) => Object.keys(matrix[groupId][rowId]).forEach((columnId) => {
        const latestByContext = getLatestDeploymentPerContext(matrix[groupId][rowId][columnId]);
        matrix[groupId][rowId][columnId] = sortBy(latestByContext, [(t: DashboardItemResource) => t.ReleaseVersion, (t: DashboardItemResource) => new Date(t.CompletedTime || t.Created)]);
    })));
    return matrix;
}
function getLatestDeploymentPerContext(deployments: DashboardItemResource[]) {
    const latestPerDeploymentContext = deployments.reduce<{
        [key: string]: DashboardItemResource;
    }>((idx: {
        [index: string]: DashboardItemResource;
    }, item: DashboardItemResource) => {
        const key = item.EnvironmentId + item.ReleaseId + item.TenantId + item.ProjectId;
        const current = idx[key] || null;
        if (!current || new Date(current.CompletedTime || current.Created) < new Date(item.CompletedTime || current.Created)) {
            idx[key] = item;
        }
        return idx;
    }, {});
    return Object.keys(latestPerDeploymentContext).map((e) => latestPerDeploymentContext[e]);
}
function limitProjects(data: Array<string | null>, limit: number): Array<string | null> {
    if (data.length <= limit) {
        return data;
    }
    return limit > 0 ? data.slice(0, limit) : [];
}
export { DataSet, Matrix, getDataSet };
