import { css, cx } from "@emotion/css";
import { Breadcrumbs } from "@octopusdeploy/design-system-components";
import type { BreadcrumbItem } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { ReactElement } from "react";
import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import type { PageAction, PrimaryPageAction } from "~/components/PageActions/PageActions";
import { PageActions } from "~/components/PageActions/PageActions";
import PageTitleHelper from "~/utils/PageTitleHelper/index";
import { useIsInPageNavVisible } from "../GlobalLayout/InPageNavVisibilityContext";
import { useIsVerticalNavigationEnabled } from "../RootRoutes/useIsVerticalNavigationEnabled";
import { LeadingSegment } from "./LeadingSegment";
export interface PageHeaderPrimaryProps {
    /**
     * The breadcrumbs showing at the top of primary header.
     *
     * The breadcrumbs is only visible when this property is defined.
     */
    breadcrumbs?: BreadcrumbItem[];
    /**
     * The logo to show to the left of the header title.
     *
     * The logo is only visible when this property is defined.
     */
    logo?: PageHeaderPrimaryLogo;
    /** The title of the PageHeaderPrimaryProps. */
    title: string;
    /**
     * The icon to show next to the header title.
     *
     * The icon next to the title is only visible when this property is defined.
     */
    titleIcon?: ReactElement;
    /** A list of chips that showing next to the header title with a divider at the beginning */
    titleChips?: ReactElement[];
    /**
     * An optional primary action to display on the right side of the header
     */
    primaryAction?: PrimaryPageAction;
    /**
     * An optional list of actions to display on the right side of the header
     */
    actions?: PageAction[];
    /**
     * An optional list of overflow items that forms an overflow menu on the right side of the header
     */
    overflowActions?: Array<MenuItem>;
    /**
     * Controls whether the loading indicator is shown.
     */
    showLoadingIndicator?: boolean;
}
export interface PageHeaderPrimaryLogo {
    /** The url of the logo */
    href: string;
    /** The accessible name of the logo */
    accessibleName: string;
}
export function PageHeaderPrimary({ breadcrumbs, logo, title, titleIcon, titleChips, primaryAction, actions, overflowActions, showLoadingIndicator }: PageHeaderPrimaryProps) {
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    const isVerticalNavigationEnabled = useIsVerticalNavigationEnabled();
    const isInPageNavVisible = useIsInPageNavVisible();
    useEffect(() => {
        PageTitleHelper.setPageTitle(title, breadcrumbs?.[0].label);
    }, [title, breadcrumbs]);
    return (<header className={cx(isVerticalNavigationEnabled && isInPageNavVisible ? headerPrimaryStylesInPageNav : headerPrimaryStyles, { [headerPrimaryMobileStyles]: !isLargerThanIpad })}>
            {breadcrumbs && <Breadcrumbs items={breadcrumbs} showBackIcon={true}/>}
            <div className={cx(headerContentStyles, { [headerContentMobileStyles]: !isLargerThanIpad })}>
                <LeadingSegment logo={logo} title={title} titleIcon={titleIcon} titleChips={titleChips} showLoadingIndicator={showLoadingIndicator}/>
                <PageActions primaryAction={primaryAction} actions={actions} overflowActions={overflowActions} alignment={isLargerThanIpad ? "end" : "start"}/>
            </div>
        </header>);
}
const headerPrimaryStyles = css({
    display: "flex",
    flexDirection: "column",
    padding: `${space["24"]} ${space["32"]} ${space["16"]} ${space["32"]}`,
    gap: space["8"],
});
const headerPrimaryStylesInPageNav = css({
    display: "flex",
    flexDirection: "column",
    padding: `${space["24"]} ${space["16"]} ${space["16"]} ${space["16"]}`,
    gap: space["8"],
});
const headerPrimaryMobileStyles = css({
    padding: `${space["24"]} ${space["16"]} ${space["12"]}`,
});
const headerContentStyles = css({
    display: "flex",
    justifyContent: "space-between",
    gap: space["48"],
    // This header content should have a min height set to the height of the buttons.
    // This prevents the header from changing size to accommodate buttons that are added or removed.
    minHeight: "2.25rem",
});
const headerContentMobileStyles = css({
    flexDirection: "column",
    gap: space["24"],
    alignItems: "unset",
});
