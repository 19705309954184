import type { BranchSpecifier, ProjectResource, ProjectSettingsMetadata } from "@octopusdeploy/octopus-server-client";
import { HasRunbooksInGit, HasVariablesInGit, Permission, ShouldUseDefaultBranch } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import { compact } from "lodash";
import * as React from "react";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import type { NavLink } from "~/components/NavigationSidebarLayout/index";
import { Navigation } from "~/components/NavigationSidebarLayout/index";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import type { NavDivider } from "~/components/PortalPageLayout/PortalPageLayout";
import { IsAllowedToSeeDeploymentsOverview } from "../ProjectsRoutes/ProjectToOverviewRedirect";
import { GitProjectLink, GitProjectLinkWithErrorStatus, IndentedProjectLink } from "./GitProjectLink";
export function ProjectLinksVNext(branch: BranchSpecifier, project: Readonly<ProjectResource>, hasConnectionError: boolean, isProjectTenantVariablesEnabled: boolean, projectMetadata: ProjectSettingsMetadata[]) {
    const projectId = project.Id;
    const projectSlug = project.Slug;
    const spaceId = project.SpaceId;
    const navigationLinks: (NavLink | NavDivider | null)[] = [];
    const releaseViewPermission = {
        permission: Permission.ReleaseView,
        project: projectId,
        tenant: "*",
    };
    const processViewPermission = {
        permission: Permission.ProcessView,
        project: projectId,
        tenant: "*",
    };
    const triggerViewPermission = {
        permission: Permission.TriggerView,
        project: projectId,
    };
    const runbookViewPermission = {
        permission: Permission.RunbookView,
        project: projectId,
        wildcard: true,
    };
    const variableViewPermission = {
        permission: Permission.VariableView,
        project: projectId,
        wildcard: true,
    };
    const tenantViewPermission = {
        permission: Permission.TenantView,
        project: projectId,
        projectGroup: "*",
        tenant: "*",
    };
    const taskViewPermission = {
        permission: Permission.TaskView,
        project: projectId,
        projectGroup: "*",
        tenant: "*",
    };
    const projectViewPermission = {
        permission: Permission.ProjectView,
        project: projectId,
        projectGroup: "*",
        tenant: "*",
    };
    if (IsAllowedToSeeDeploymentsOverview(projectId)) {
        navigationLinks.push(NavigationItem("Deployments", links.deploymentsPage.generateUrl({ spaceId, projectSlug }), true, releaseViewPermission));
        navigationLinks.push(GitNavigationItem("Process", links.deploymentProcessPage.generateUrl({ spaceId, projectSlug }), ShouldUseDefaultBranch(branch) ? links.deploymentProcessPage.generateUrl({ spaceId, projectSlug }) : links.branchDeploymentProcessPage.generateUrl({ spaceId, projectSlug, branchName: branch }), undefined, processViewPermission));
        navigationLinks.push(NavigationItem("Channels", links.channelsPage.generateUrl({ spaceId, projectSlug }), undefined, processViewPermission));
        navigationLinks.push(NavigationItem("Releases", links.releasesPage.generateUrl({ spaceId, projectSlug }), undefined, releaseViewPermission));
        navigationLinks.push(NavigationItem("Triggers", links.deploymentTriggersPage.generateUrl({ spaceId, projectSlug }), undefined, triggerViewPermission));
        navigationLinks.push(GitNavigationItem("Settings", links.deploymentProcessSettingsPage.generateUrl({ spaceId, projectSlug }), ShouldUseDefaultBranch(branch) ? links.deploymentProcessSettingsPage.generateUrl({ spaceId, projectSlug }) : links.branchDeploymentProcessSettingsPage.generateUrl({ spaceId, projectSlug, branchName: branch })));
        navigationLinks.push("divider");
    }
    if (isAllowed(runbookViewPermission)) {
        navigationLinks.push(NavigationItem("Operations", links.projectOperationsPage.generateUrl({ spaceId, projectSlug }), true));
        navigationLinks.push(GitRunbooksNavigationItem("Runbooks", links.projectRunbooksPage.generateUrl({ spaceId, projectSlug }), ShouldUseDefaultBranch(branch) ? links.projectRunbooksPage.generateUrl({ spaceId, projectSlug }) : links.branchProjectRunbooksPage.generateUrl({ spaceId, projectSlug, branchName: branch })));
        navigationLinks.push(NavigationItem("Runbook Triggers", links.projectRunbookTriggersPage.generateUrl({ spaceId, projectSlug }), undefined, triggerViewPermission));
        navigationLinks.push("divider");
    }
    if (isAllowed(variableViewPermission)) {
        navigationLinks.push(GitVariablesNavigationItem("Project Variables", links.variablesPage.generateUrl({ spaceId, projectSlug }), ShouldUseDefaultBranch(branch) ? links.variablesPage.generateUrl({ spaceId, projectSlug }) : links.branchVariablesPage.generateUrl({ spaceId, projectSlug, branchName: branch }), true));
        if (isProjectTenantVariablesEnabled) {
            navigationLinks.push(NavigationItem("Tenant Variables", links.projectTenantProjectTemplatesPage.generateUrl({ spaceId, projectSlug })));
        }
        else {
            navigationLinks.push(NavigationItem("Project Templates", links.variableTemplatesPage.generateUrl({ spaceId, projectSlug })));
            navigationLinks.push(NavigationItem("Common Templates", links.commonVariableTemplatesPage.generateUrl({ spaceId, projectSlug })));
        }
        navigationLinks.push(NavigationItem("Library Variable Sets", links.libraryVariablesPage.generateUrl({ spaceId, projectSlug })));
        navigationLinks.push(GitVariablesNavigationItem("All Variables", links.allVariablesPage.generateUrl({ spaceId, projectSlug }), ShouldUseDefaultBranch(branch) ? links.allVariablesPage.generateUrl({ spaceId, projectSlug }) : links.branchAllVariablesPage.generateUrl({ spaceId, projectSlug, branchName: branch })));
        navigationLinks.push(GitVariablesNavigationItem("Variable Preview", links.variablesPreviewPage.generateUrl({ spaceId, projectSlug }), ShouldUseDefaultBranch(branch) ? links.variablesPreviewPage.generateUrl({ spaceId, projectSlug }) : links.branchVariablesPreviewPage.generateUrl({ spaceId, projectSlug, branchName: branch })));
        navigationLinks.push("divider");
    }
    if (isAllowed(tenantViewPermission)) {
        navigationLinks.push(Navigation.navItem("Tenants", links.projectTenantsPage.generateUrl({ spaceId, projectSlug })));
        navigationLinks.push("divider");
    }
    if (isAllowed(taskViewPermission)) {
        navigationLinks.push(Navigation.navItem("Tasks", links.projectTasksPage.generateUrl({ spaceId, projectSlug })));
        navigationLinks.push("divider");
    }
    if (isAllowed(projectViewPermission)) {
        navigationLinks.push(NavigationItem("Insights", links.projectInsightsOverviewPage.generateUrl({ spaceId, projectSlug })));
        navigationLinks.push("divider");
        navigationLinks.push(NavigationItem("Project Settings", links.projectSettingsPage.generateUrl({ spaceId, projectSlug }), true));
        navigationLinks.push(GitNavigationItemWithErrorStatus("Version Control", links.projectVersionControlSettingsPage.generateUrl({ spaceId, projectSlug }), links.projectVersionControlSettingsPage.generateUrl({ spaceId, projectSlug })));
        if (projectMetadata && projectMetadata.length > 0) {
            navigationLinks.push(NavigationItem("ITSM Providers", links.projectITSMProvidersControlSettingsPage.generateUrl({ spaceId, projectSlug })));
        }
        if (isFeatureToggleEnabled("DynamicEnvironmentsFeatureToggle")) {
            navigationLinks.push(NavigationItem(<React.Fragment>Dynamic Environments</React.Fragment>, links.projectDynamicEnvironmentSettingsPage.generateUrl({ spaceId, projectSlug })));
        }
    }
    return compact(navigationLinks);
    // Helpers
    function GitRunbooksNavigationItem(label: string | JSX.Element, dbLink: LinkHref, gitLink: LinkHref, exact?: boolean, permission?: PermissionCheckProps) {
        const labelElement = HasRunbooksInGit(project.PersistenceSettings) ? <GitProjectLink label={label}/> : <IndentedProjectLink label={label}/>;
        const link = HasRunbooksInGit(project.PersistenceSettings) ? gitLink : dbLink;
        return Navigation.navItem(labelElement, link, exact, permission);
    }
    function GitVariablesNavigationItem(label: string | JSX.Element, dbLink: LinkHref, gitLink: LinkHref, exact?: boolean, permission?: PermissionCheckProps) {
        const labelElement = HasVariablesInGit(project.PersistenceSettings) ? <GitProjectLink label={label}/> : <IndentedProjectLink label={label}/>;
        const link = HasVariablesInGit(project.PersistenceSettings) ? gitLink : dbLink;
        return Navigation.navItem(labelElement, link, exact, permission);
    }
    function GitNavigationItem(label: string | JSX.Element, dbLink: LinkHref, gitLink: LinkHref, exact?: boolean, permission?: PermissionCheckProps) {
        const labelElement = project.IsVersionControlled ? <GitProjectLink label={label}/> : <IndentedProjectLink label={label}/>;
        const link = project.IsVersionControlled ? gitLink : dbLink;
        return Navigation.navItem(labelElement, link, exact, permission);
    }
    function GitNavigationItemWithErrorStatus(label: string | JSX.Element, dbLink: LinkHref, gitLink: LinkHref, exact?: boolean, permission?: PermissionCheckProps) {
        const labelElement = project.IsVersionControlled ? <GitProjectLinkWithErrorStatus label={label} hasError={hasConnectionError}/> : <IndentedProjectLink label={label}/>;
        const link = project.IsVersionControlled ? gitLink : dbLink;
        return Navigation.navItem(labelElement, link, exact, permission);
    }
    function NavigationItem(label: string | JSX.Element, dbLink: LinkHref, exact?: boolean, permission?: PermissionCheckProps) {
        return Navigation.navItem(<IndentedProjectLink label={label}/>, dbLink, exact, permission);
    }
}
