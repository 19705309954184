import { css } from "@emotion/css";
import { Divider } from "@octopusdeploy/design-system-components";
import { themeTokens, space } from "@octopusdeploy/design-system-tokens";
import type { DetailedProjectsDashboardResource, InitialProjectsDashboardResource, DetailedDashboardProjectGroup, InitialDashboardProjectGroup, ProjectsDashboardFilter } from "@octopusdeploy/octopus-server-client";
import { useState } from "react";
import * as React from "react";
import { dashboardRefreshIntervalInMs } from "~/areas/dashboard/DashboardOverview/TemporaryAllProjectsConsolidationPage";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import { useRefreshLoop } from "~/hooks/useRefreshLoop";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
export interface ProjectsDashboardProps {
    doBusyTask: DoBusyTask;
}
type ProjectsDashboardData = DetailedProjectsDashboardResource | InitialProjectsDashboardResource;
export function ProjectsDashboard({ doBusyTask }: ProjectsDashboardProps) {
    const [page, setPage] = useState<number>(1);
    const [itemPerPage, setItemPerPage] = useState<number>(5);
    const [initialDashboardData, setInitialDashboardData] = useState<InitialProjectsDashboardResource | undefined>(undefined);
    const dashboardFilters: ProjectsDashboardFilter = getDashboardFilters(page, itemPerPage);
    useDoBusyTaskEffect(doBusyTask, async () => {
        setInitialDashboardData(await repository.Dashboards.getInitialProjectsDashboard(dashboardFilters));
    }, []);
    const detailedDashboardData = useDetailedDashboardDataSource(doBusyTask, dashboardFilters);
    const dashboardData: ProjectsDashboardData | undefined = detailedDashboardData ?? initialDashboardData;
    if (dashboardData === undefined) {
        // Later we will decide if we should show a placeholder here, or lift-up the async action in the tree
        // For now just returning null
        return null;
    }
    return (<div>
            {dashboardData.ProjectGroups.map((projectGroup) => (<ProjectGroupRenderer key={projectGroup.Id} projectGroup={projectGroup}/>))}
        </div>);
}
interface ProjectGroupRendererProps {
    projectGroup: InitialDashboardProjectGroup | DetailedDashboardProjectGroup;
}
function ProjectGroupRenderer({ projectGroup }: ProjectGroupRendererProps) {
    return (<div className={projectGroupStyles}>
            <div className={projectGroupTitleSectionStyles}>
                <h2>{projectGroup.Name}</h2>
            </div>
            <div>table here</div>
            <Divider />
        </div>);
}
const projectGroupTitleSectionStyles = css({
    display: "flex",
    flexDirection: "column",
    backgroundColor: themeTokens.color.background.secondary.default,
});
const projectGroupStyles = css({
    display: "flex",
    flexDirection: "column",
    gap: space["12"],
});
function useDetailedDashboardDataSource(doBusyTask: DoBusyTask, paginationParams: ProjectsDashboardFilter) {
    const [detailedDashboardData, setDetailedDashboardData] = useState<DetailedProjectsDashboardResource | undefined>(undefined);
    const dashboardRequest = useDoBusyTaskEffect(doBusyTask, async () => {
        const data = await repository.Dashboards.getDetailedProjectsDashboard(paginationParams);
        setDetailedDashboardData(data);
    }, [], { timeOperationOptions: timeOperationOptions.forRefresh() });
    useRefreshLoop(dashboardRequest, dashboardRefreshIntervalInMs);
    return detailedDashboardData;
}
function getDashboardFilters(page: number, itemPerPage: number) {
    const skip = (page - 1) * itemPerPage;
    const take = itemPerPage;
    return { skip, take };
}
