import { css, cx } from "@emotion/css";
import { CircularProgress, Divider } from "@octopusdeploy/design-system-components";
import { space, text, themeTokens, colorScales, borderRadius } from "@octopusdeploy/design-system-tokens";
import type { ReactElement } from "react";
import React, { Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import type { PageHeaderPrimaryLogo } from "./PageHeaderPrimary";
export interface LeadingSegmentProps {
    logo?: PageHeaderPrimaryLogo;
    title: string;
    titleIcon?: ReactElement;
    showLoadingIndicator?: boolean;
    titleChips?: ReactElement[];
}
export function LeadingSegment({ logo, title, titleIcon, showLoadingIndicator, titleChips }: LeadingSegmentProps) {
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    return (<div className={cx(leadingSegmentStyles, { [leadingSegmentMobileStyles]: !isLargerThanIpad })}>
            {logo && (<div className={logoContainerStyles}>
                    <img className={logoStyles} src={logo.href} alt={logo.accessibleName}/>
                </div>)}
            <div className={titleContainerStyles}>
                <h2 title={title} className={titleStyles}>
                    {title}
                </h2>
                {showLoadingIndicator && <CircularProgress size="small"/>}
                {isLargerThanIpad && titleIcon}
            </div>
            {titleChips && (<div className={cx(chipsWrapperStyles, { [chipsWrapperMobileStyles]: !isLargerThanIpad })}>
                    {isLargerThanIpad && <Divider orientation={"vertical"}/>}
                    {titleChips.map((chip, index) => (<Fragment key={index}>{chip}</Fragment>))}
                </div>)}
        </div>);
}
const leadingSegmentStyles = css({
    minWidth: 0,
    display: "flex",
    alignItems: "center",
    gap: space["12"],
});
const leadingSegmentMobileStyles = css({
    gap: space["16"],
    flexWrap: "wrap",
});
const logoContainerStyles = css({
    width: "2.25rem",
    height: "2.25rem",
    borderRadius: borderRadius.small,
    display: "flex",
    flexShrink: 0,
    justifyContent: "center",
    background: colorScales.white,
});
const logoStyles = css({
    borderRadius: borderRadius.extraSmall,
    objectFit: "scale-down",
    width: "100%",
    height: "100%",
});
const titleContainerStyles = css({
    flex: "1",
    display: "flex",
    gap: space["12"],
    alignItems: "center",
    overflow: "hidden",
});
const titleStyles = css({
    all: "unset",
    font: text.interface.heading.small,
    color: themeTokens.color.text.primary,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
});
const chipsWrapperStyles = css({
    display: "flex",
    gap: space["12"],
});
const chipsWrapperMobileStyles = css({
    flexBasis: "100%",
});
