// eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
import type { DeploymentFreezeDetailProject, EnvironmentResource, ProjectGroupLookup, ProjectSummaryResource, SpaceResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { cloneDeep, intersection } from "lodash";
import pluralize from "pluralize";
import * as React from "react";
import type { DeploymentFreezeModel } from "~/areas/configuration/components/DeploymentFreezes/EditDeploymentFreeze";
import type { ConnectionWizardDialogLayoutProps } from "~/areas/projects/components/ProjectTenants/ConnectionWizardDialogLayout";
import { ConnectionWizardDialogLayoutInternal } from "~/areas/projects/components/ProjectTenants/ConnectionWizardDialogLayout";
import type { NamedItemWithLogo } from "~/areas/projects/components/ProjectTenants/PanelSelector";
import PanelSelector, { SelectItemType } from "~/areas/projects/components/ProjectTenants/PanelSelector";
import type { NamedItemWithLogoAndEnvironments } from "~/areas/projects/components/ProjectTenants/SelectEnvironments";
import SelectEnvironments from "~/areas/projects/components/ProjectTenants/SelectEnvironments";
import type { Filter, FilterValue } from "~/areas/tenants/components/Filtering/FilterBuilder/filterBuilderTypes";
import { createProjectGroupFilter, createSpaceFilter, FilterMultiplicity, getExcludedProjectGroupValue, getExcludedSpaceValue, getIncludedProjectGroupValue, getIncludedSpaceValue, } from "~/areas/tenants/components/Filtering/FilterBuilder/filterBuilderTypes";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import DataLoader from "~/components/DataLoader/index";
import DialogLayoutConnect from "~/components/Dialog/DialogLayoutConnect";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
const AssignProjectsToFreezeWizardDialogLayout = DialogLayoutConnect.to<ConnectionWizardDialogLayoutProps>(ConnectionWizardDialogLayoutInternal);
AssignProjectsToFreezeWizardDialogLayout.displayName = "AssignProjectsToFreezeWizardDialogLayout";
interface ConnectFreezeProjectsDialogProps {
    alreadyConnectedProjectsIds: string[];
    deploymentFreeze: DeploymentFreezeModel;
    onConnected: (deploymentFreeze: DeploymentFreezeModel, numberOfProjectsConnected: number) => void;
}
export default function ConnectFreezeProjectsDialog(props: ConnectFreezeProjectsDialogProps) {
    return (<InitialDataLoader load={loadInitialData.bind(null)} operationName="AssignProjects" renderWhenLoaded={(data) => <ConnectFreezeProjects {...data} {...props}/>} renderAlternate={({ busy, errors }) => <SaveDialogLayout title="Assign Projects" busy={busy} errors={errors} onSaveClick={() => Promise.resolve(true)}/>}/>);
}
interface InitialDataProps {
    spaces: SpaceResource[];
    projects: ProjectSummaryResource[];
    projectGroupLookup: ProjectGroupLookup[];
}
const InitialDataLoader = DataLoader<InitialDataProps>();
const loadInitialData = async (): Promise<InitialDataProps> => {
    const projects = await repository.Projects.summariesAcrossAllSpaces();
    const spaces = await repository.Spaces.all();
    const projectGroupLookup = await repository.ProjectGroups.getProjectGroupsLookupAcrossAllSpaces();
    return { projects, spaces, projectGroupLookup };
};
type ConnectFreezeProjectsProps = ConnectFreezeProjectsDialogProps & InitialDataProps;
interface ConnectFreezeProjectsState extends DataBaseComponentState {
    availableProjects: ProjectSummaryResource[];
    selectedProjectEnvironments: NamedItemWithLogoAndEnvironments[];
    availableEnvironmentsPerProject: Map<string, string[]>;
    availableEnvironments: Map<string, EnvironmentResource>;
    filter: SelectProjectsFilterParameters;
    totalAvailableProjectCount: number;
    spaces: SpaceResource[];
    projectGroupLookup: ProjectGroupLookup[];
}
class ConnectFreezeProjects extends DataBaseComponent<ConnectFreezeProjectsProps, ConnectFreezeProjectsState> {
    constructor(props: ConnectFreezeProjectsProps) {
        super(props);
        const availableProjects = props.projects.filter((p) => !props.alreadyConnectedProjectsIds.includes(p.Id));
        const alreadyConnected = props.deploymentFreeze.scope;
        this.state = {
            availableProjects,
            totalAvailableProjectCount: availableProjects.length,
            selectedProjectEnvironments: alreadyConnected,
            availableEnvironmentsPerProject: new Map(),
            availableEnvironments: new Map(),
            filter: { name: "" },
            spaces: this.props.spaces,
            projectGroupLookup: this.props.projectGroupLookup,
        };
    }
    canConnect = () => this.state.selectedProjectEnvironments.length > 0;
    connect = async () => {
        return this.doBusyTask(async () => {
            const freeze = cloneDeep(this.props.deploymentFreeze);
            freeze.scope = [
                ...this.state.selectedProjectEnvironments.map((projectEnvironment) => ({
                    Id: projectEnvironment.Id,
                    Name: projectEnvironment.Name,
                    LogoLink: projectEnvironment.LogoLink,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    SpaceId: projectEnvironment.SpaceId!,
                    Environments: projectEnvironment.Environments,
                })),
            ];
            setTimeout(() => this.props.onConnected(freeze, this.state.selectedProjectEnvironments.length), 0);
        });
    };
    nameFilterChanged = async (name: string): Promise<boolean> => {
        return await this.reloadProjects({ ...this.state.filter, name });
    };
    getFilters = (): Filter[] => {
        const projectGroupsWithSpaceId = this.state.projectGroupLookup.map((pg) => {
            const space = this.state.spaces.find((s) => s.Id === pg.SpaceId);
            return { ...pg, Name: `${pg.Name} - ${space?.Name}` };
        });
        return [
            createSpaceFilter(this.state.spaces, this.state.filter.filterBySpace, this.state.filter.filterByExcludedSpace, FilterMultiplicity.Always),
            createProjectGroupFilter(projectGroupsWithSpaceId, this.state.filter.filterByProjectGroup, this.state.filter.filterByExcludedProjectGroup, FilterMultiplicity.Always),
        ];
    };
    filtersChanged = async (filterValues: FilterValue[]): Promise<boolean> => {
        const filterByProjectGroup = getIncludedProjectGroupValue(filterValues);
        const filterByExcludedProjectGroup = getExcludedProjectGroupValue(filterValues);
        const filterBySpace = getIncludedSpaceValue(filterValues);
        const filterByExcludedSpace = getExcludedSpaceValue(filterValues);
        return await this.reloadProjects({ ...this.state.filter, filterByProjectGroup, filterByExcludedProjectGroup, filterBySpace, filterByExcludedSpace });
    };
    reloadProjects = async (filter: SelectProjectsFilterParameters) => this.doBusyTask(async () => {
        const filteredProjects = await repository.Projects.summariesAcrossAllSpaces({
            filterByName: filter.name,
            filterBySpace: filter.filterBySpace,
            filterByExcludedSpace: filter.filterByExcludedSpace,
            filterByProjectGroup: filter.filterByProjectGroup,
            filterByExcludedProjectGroup: filter.filterByExcludedProjectGroup,
        });
        const availableProjects = filteredProjects.filter((p) => !this.props.alreadyConnectedProjectsIds.includes(p.Id));
        this.setState({ availableProjects, filter });
    });
    getAvailableEnvironments = async (): Promise<EnvironmentResource[]> => {
        const availableEnvironmentsResponse = await repository.Projects.getAvailableEnvironmentsForProjectsAcrossAllSpaces(this.state.selectedProjectEnvironments.map((p) => p.Id));
        const environmentsWithSpace = availableEnvironmentsResponse.Environments.map((e) => {
            const space = this.state.spaces.find((s) => s.Id === e.SpaceId);
            return { ...e, Name: `${e.Name} - ${space?.Name}` };
        });
        const projectEnvironmentsMap = new Map(Object.entries(availableEnvironmentsResponse.ProjectEnvironments));
        this.setState({ availableEnvironments: availableEnvironmentsResponse.Environments.reduce((map, env) => map.set(env.Id, env), new Map<string, EnvironmentResource>()), availableEnvironmentsPerProject: projectEnvironmentsMap });
        return environmentsWithSpace;
    };
    onProjectsSelected = (projects: NamedItemWithLogo[]) => {
        const selectedProjects = projects.map((p) => p.Id);
        const selectedProjectEnvironments = projects.map((p) => ({ ...p, Environments: [] }));
        const availableProjects = this.props.projects.filter((p) => !selectedProjects.includes(p.Id));
        this.setState({ selectedProjectEnvironments, availableProjects });
    };
    onEnvironmentsSelected = (environmentIds: string[]) => {
        const assignAvailableEnvironmentsToProject = (project: NamedItemWithLogoAndEnvironments) => {
            const availableEnvironmentIdsInProject = this.state.availableEnvironmentsPerProject.get(project.Id) ?? [];
            const selectedAvailableEnvironmentIdsInProject = intersection(availableEnvironmentIdsInProject, environmentIds);
            const environments = selectedAvailableEnvironmentIdsInProject.map((id) => {
                const name = this.state.availableEnvironments.get(id)?.Name ?? "Unknown environment";
                return { Id: id, Name: name };
            });
            return { ...project, Environments: environments };
        };
        const selectedProjectEnvironments = this.state.selectedProjectEnvironments.map(assignAvailableEnvironmentsToProject);
        this.setState({ selectedProjectEnvironments });
    };
    hasFilteredApplied = () => {
        if (this.state.filter.name !== "") {
            return true;
        }
        if (this.state.filter.filterBySpace || this.state.filter.filterByExcludedSpace) {
            return true;
        }
        if (this.state.filter.filterByProjectGroup || this.state.filter.filterByExcludedProjectGroup) {
            return true;
        }
        return false;
    };
    render() {
        const disabled = !this.canConnect();
        const availableProjects = {
            records: this.state.availableProjects.map((item) => ({ Id: item.Id, Name: item.Name, LogoLink: item.Logo })),
            totalCount: this.state.availableProjects.length,
        };
        return (<AssignProjectsToFreezeWizardDialogLayout title={this.props.deploymentFreeze.name.length > 0 ? `Assign Projects to ${this.props.deploymentFreeze.name}` : "Assign projects"} onSaveClick={this.connect} saveButtonDisabled={disabled} busy={this.state.busy} errors={this.errors} savePermission={{ permission: Permission.DeploymentFreezeAdminister }} saveButtonLabel={`Assign ${pluralize("Project", this.state.selectedProjectEnvironments.length, true)}`} wizardStepNames={["Select projects", "Select environments"]} selectItemType={SelectItemType.Project}>
                <PanelSelector items={{ ...availableProjects, totalCount: this.state.totalAvailableProjectCount }} selectedItems={this.state.selectedProjectEnvironments} updateSelectedItems={this.onProjectsSelected.bind(this)} filters={this.getFilters()} onFilterChanged={this.filtersChanged.bind(this)} onNameFilterChanged={this.nameFilterChanged.bind(this)} selectItemType={SelectItemType.Project} isFiltered={this.hasFilteredApplied()} filteredName={this.state.filter.name}/>
                <SelectEnvironments selectedItems={this.state.selectedProjectEnvironments} getAvailableEnvironments={this.getAvailableEnvironments} updateSelectedEnvironments={this.onEnvironmentsSelected.bind(this)} selectItemType={SelectItemType.Project} doBusyTask={this.doBusyTask} showPreviouslySelectedEnvironments={true}/>
            </AssignProjectsToFreezeWizardDialogLayout>);
    }
    static displayName = "ConnectFreezeProjects";
}
const addSelectedProjectAndEnvironments = (current: DeploymentFreezeDetailProject[], p: DeploymentFreezeDetailProject) => {
    current.push(p);
    return current;
};
export interface SelectProjectsFilterParameters {
    name: string;
    filterBySpace?: string;
    filterByExcludedSpace?: string;
    filterByProjectGroup?: string;
    filterByExcludedProjectGroup?: string;
}
