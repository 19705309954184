/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { NavigationButton } from "@octopusdeploy/design-system-components";
import { themeTokens, space } from "@octopusdeploy/design-system-tokens";
import type { ProjectGroupResource, ProjectResource } from "@octopusdeploy/octopus-server-client";
import { DashboardRenderMode, Permission } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import { isEqual } from "lodash";
import MobileDetect from "mobile-detect";
import * as React from "react";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import type { RenderDashboardProps } from "~/areas/dashboard/DashboardOverview/DashboardOverviewPage";
import { ProjectsDashboard } from "~/areas/dashboard/DashboardOverview/ProjectsDashboard";
import type { DeploymentOverviewFilters } from "~/areas/projects/components/DashboardDataSource/DataCube";
import { DimensionTypes } from "~/areas/projects/components/DashboardDataSource/DataCube";
import SpaceDashboardDataSource from "~/areas/projects/components/DashboardDataSource/SpaceDashboardDataSource";
import { getImportExportMenuItems } from "~/areas/projects/components/ImportExport/ImportExportMenu";
import ProjectDashboard from "~/areas/projects/components/ProjectDashboard/index";
import { AddProjectGroup } from "~/areas/projects/components/Projects/AddProjectGroup";
import { repository } from "~/clientInstance";
import Chip from "~/components/Chips/Chip";
import type { CommonChipProps } from "~/components/Chips/types";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import Dialog from "~/components/Dialog/Dialog";
import { FullWidthPageLayout } from "~/components/FullWidthPageLayout/FullWidthPageLayout";
import { InternalRedirect } from "~/components/Navigation/InternalRedirect/index";
import type { PageAction, PrimaryPageAction } from "~/components/PageActions/PageActions";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import AddNewProjectDialog from "~/components/ProjectBasedActivation/AddNewProjectDialog";
import DashboardOnboardingLayout from "~/components/ProjectBasedActivation/DashboardOnboardingLayout";
import type { IQuery } from "~/components/QueryStringFilters/QueryStringFilters";
import { QueryStringFilters } from "~/components/QueryStringFilters/QueryStringFilters";
import { useIsDashboardVNextEnabled } from "~/components/RootRoutes/useIsDashboardVNextEnabled";
import { useIsPageHeaderVNextEnabledOutsideOfProjects } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import Section from "~/components/Section";
import { withTheme } from "~/components/Theme";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import FilterSearchBox from "../../../components/FilterSearchBox/index";
import DashboardLimitSummary from "./DashboardLimitSummary";
import styles from "./style.module.less";
interface DashboardOverviewFilter {
    projectGroupId: string;
    projectName: string;
}
interface DashboardOverviewState {
    filter: DashboardOverviewFilter;
    matchCount: number;
    onboardingProject?: ProjectResource;
    redirectTo?: LinkHref;
    isDashboardLoaded: boolean;
    projectGroups: ProjectGroupResource[] | undefined;
}
const defaultFilter: DashboardOverviewFilter = {
    projectName: "",
    projectGroupId: "",
};
interface DashboardOverviewQuery extends IQuery {
    projectGroupId?: string;
    projectName?: string;
}
interface DashboardOverviewProps {
    spaceId: string;
}
interface DashboardOverviewInternalProps extends DashboardOverviewProps {
    dispatchAction: AnalyticActionDispatcher;
    isPageHeaderVNextEnabled: boolean;
    isDashboardVNextEnabled: boolean;
}
export const dashboardRefreshIntervalInMs = 6000;
const DashboardQueryStringFilters = QueryStringFilters.For<DashboardOverviewFilter, DashboardOverviewQuery>();
const DashboardDispatcher = ({ dispatchAction }: {
    dispatchAction: AnalyticActionDispatcher;
}) => {
    const [dispatched, setDispatched] = React.useState(false);
    if (!dispatched) {
        dispatchAction("Dashboard Viewed", { action: Action.View, resource: "Dashboard" });
        setDispatched(true);
    }
    return <></>;
};
const FiltersAppliedChip = (props: CommonChipProps) => withTheme((theme) => (<Chip backgroundColor={theme.infoBackground} borderColor={theme.infoBorder} labelColor={theme.infoText} {...props} description="You have filters configured that are potentially limiting the projects visible below">
            Filters applied
        </Chip>));
class TemporaryAllProjectsConsolidationPageInternal extends DataBaseComponent<DashboardOverviewInternalProps, DashboardOverviewState> {
    constructor(props: DashboardOverviewInternalProps) {
        super(props);
        this.state = {
            filter: defaultFilter,
            matchCount: 0,
            onboardingProject: undefined,
            redirectTo: undefined,
            isDashboardLoaded: false,
            projectGroups: undefined,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const projectGroups = isAllowed({
                permission: Permission.ProjectGroupView,
                projectGroup: "*",
            })
                ? await repository.ProjectGroups.all()
                : [];
            this.setState({ projectGroups });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    render() {
        if (this.state.redirectTo !== undefined) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }
        return (<>
                <DashboardQueryStringFilters filter={this.state.filter} getQuery={getQueryFromFilters} getFilter={getFilter} onFilterChange={(filter) => this.setState({ filter })}/>
                <SpaceDashboardDataSource render={this.renderDataSource} doBusyTask={this.doBusyTask} onDashboardLoad={() => this.onDashboardLoad()}/>
            </>);
    }
    private onDashboardLoad() {
        this.setState({ isDashboardLoaded: true });
    }
    private showDashboard(dashboardData: RenderDashboardProps) {
        const hasFilter = !isEqual(defaultFilter, this.state.filter);
        // Disable autoFocus filtering for mobile (Android has issues and is annoying users).
        const md = new MobileDetect(window.navigator.userAgent);
        const autoFocus = md.isPhoneSized() ? false : true;
        return (<Section className={this.props.isPageHeaderVNextEnabled && styles.dashboardContainerVNext}>
                <DashboardDispatcher dispatchAction={this.props.dispatchAction}/>
                <div className={filterSectionStyles} key="A" role="search">
                    <div className={filtersContainerStyles}>
                        <div className={styles.filterField}>
                            <FilterSearchBox placeholder="Search projects..." inputClassName={styles.filterInput} value={this.state.filter.projectName} onChange={this.handleNameChange} autoFocus={autoFocus} fullWidth={true}/>
                        </div>
                        <div className={serverBasedFilterStyles}>
                            <NavigationButton label={"Advanced filters"} href={links.dashboardConfigurationPage.generateUrl({ spaceId: this.props.spaceId })}/>
                            {dashboardData.isFiltered && (<span className={filtersAppliedTextStyles}>
                                    <FiltersAppliedChip />
                                </span>)}
                        </div>
                    </div>
                    <DashboardLimitSummary matchCount={this.state.matchCount} projectLimit={dashboardData.projectLimit ?? null} hasFilter={hasFilter} totalProjects={Object.keys(dashboardData.cube!.projectIndex).length}/>
                </div>
                {this.props.isDashboardVNextEnabled ? (<ProjectsDashboard doBusyTask={this.doBusyTask}/>) : (<ProjectDashboard spaceId={this.props.spaceId} key="B" cube={dashboardData.cube!} filters={this.createDashboardFilters()} maximumRows={dashboardData.projectLimit} showDeploymentCounts={true} onProjectCountChanged={this.handleProjectCountChange} dashboardRenderMode={DashboardRenderMode.VirtualizeColumns} showProjectGroupEditLink={true}/>)}
            </Section>);
    }
    private renderDataSource = (dataSource: RenderDashboardProps) => {
        const addGroupAction: PageAction = {
            type: "dialog",
            label: "Add Project Group",
            hasPermissions: isAllowed({ permission: Permission.ProjectGroupCreate, projectGroup: "*" }),
            buttonType: "secondary",
            onClick: () => this.props.dispatchAction("Add Project Group", { resource: "Project Group", action: Action.Add }),
            renderDialog: ({ closeDialog, isOpen }) => (<Dialog open={isOpen} onRequestClose={closeDialog}>
                    {/* After new Project Group is created in existing Projects page, it redirects to itself with the new projectGroupId as query param.
                    Then the componentDidUpdate will trigger, and Project Groups will be reloaded, hence showing the newly created one.
                    However, for this temporary page, how we show empty Project Group is still TBD, therefore just closing the dialog here for now.
                    It is not good user experience, because user does not know whether a new Project Group is created or not, we will come back to solve this.
                  */}
                    <AddProjectGroup projectGroupCreated={(_id) => closeDialog()}/>
                </Dialog>),
        };
        const addProjectAction: PrimaryPageAction = {
            type: "dialog",
            label: this.state.projectGroups && this.state.projectGroups.length > 0 ? "Add Project" : "Add a Project Group before adding a new project",
            hasPermissions: isAllowed({ permission: Permission.ProjectCreate, projectGroup: "*" }),
            disabled: this.state.projectGroups?.length === 0,
            onClick: () => this.props.dispatchAction("Add Project", { resource: "Project", action: Action.Add }),
            renderDialog: ({ closeDialog, isOpen }) => (<AddNewProjectDialog open={isOpen} close={(project) => {
                    // there is following commented out conditional check in original Projects page,
                    // need to confirm if this is necessary, we could save a http call as well without it.
                    // if (!this.state.hasProjects) {
                    if (project) {
                        this.props.dispatchAction("Save Project", { action: Action.Save, resource: "Project" });
                    }
                    else {
                        this.props.dispatchAction("Cancel Project Creation", { action: Action.Cancel, resource: "Project" });
                    }
                    // }
                    closeDialog();
                }}/>),
        };
        if (this.state.isDashboardLoaded) {
            const totalProjects = Object.keys(dataSource.cube!.projectIndex).length;
            const hasCompletedDeployments = dataSource.cube!.deployments.find((d) => d.IsCompleted) !== undefined;
            const canContinueOnboarding = totalProjects === 1 && !hasCompletedDeployments;
            if (hasReachedMinimumThresholdForHidingOnboardingOnDashboard(dataSource) || canContinueOnboarding) {
                const project = dataSource.cube!.projectIndex[Object.keys(dataSource.cube!.projectIndex)[0]];
                return <DashboardOnboardingLayout spaceId={this.props.spaceId} canContinueOnboarding={canContinueOnboarding} onboardingProject={project}/>;
            }
        }
        return (<FullWidthPageLayout areaTitleLink={links.dashboardPage.generateUrl({ spaceId: this.props.spaceId })} areaTitle="All Projects" pageActions={[addGroupAction]} primaryAction={addProjectAction} overflowActions={getImportExportMenuItems(this.props.spaceId)} busy={this.state.isDashboardLoaded && this.state.projectGroups !== undefined ? false : this.state.busy} errors={this.errors}>
                {this.renderMainContent(dataSource)}
            </FullWidthPageLayout>);
    };
    private renderMainContent = (dataSource: RenderDashboardProps) => {
        if (!this.state.isDashboardLoaded || this.state.projectGroups === undefined)
            return null;
        return this.showDashboard(dataSource);
    };
    private handleGroupChange = (projectGroupId: string | undefined) => {
        this.setState((prev) => ({ filter: { ...prev.filter, projectGroupId } }));
    };
    private handleNameChange = (projectName: string) => {
        this.setState((prev) => ({ filter: { ...prev.filter, projectName } }));
    };
    private createDashboardFilters: () => DeploymentOverviewFilters = () => {
        const filter = this.state.filter;
        const groupFilters = filter.projectGroupId !== "" ? { [filter.projectGroupId]: true } : null!;
        const projectNameFilters = filter.projectName !== "" ? { [filter.projectName]: true } : null!;
        return {
            rowDimension: DimensionTypes.Project,
            columnDimension: DimensionTypes.Environment,
            groupBy: DimensionTypes.ProjectGroup,
            [DimensionTypes.ProjectGroup]: groupFilters,
            [DimensionTypes.ProjectName]: projectNameFilters,
        };
    };
    private handleProjectCountChange = (matchCount: number) => {
        // don't re-render if count is the same. Easier to check
        // here than shouldComponentUpdate
        if (matchCount !== this.state.matchCount) {
            this.setState({ matchCount });
        }
    };
    static displayName = "TemporaryAllProjectsConsolidationPageInternal";
}
function getQueryFromFilters(filter: DashboardOverviewFilter): DashboardOverviewQuery {
    return {
        projectGroupId: filter.projectGroupId,
        projectName: filter.projectName,
    };
}
function getFilter(query: DashboardOverviewQuery): DashboardOverviewFilter {
    return {
        projectGroupId: query.projectGroupId || "",
        projectName: query.projectName || "",
    };
}
/*
This component is a temporary component that consolidate current ProjectsPage and DashboardOverviewPage, the Frontend Foundation team is working on merging these two pages.
This component is created to be used in Vertical Navigation dogfooding and as an exploration for the following work, and it should only be used behind feature flag.
*/
export const TemporaryAllProjectsConsolidationPage = ({ spaceId }: DashboardOverviewProps) => {
    const dispatchAction = useAnalyticActionDispatch();
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabledOutsideOfProjects();
    const isDashboardVNextEnabled = useIsDashboardVNextEnabled();
    return <TemporaryAllProjectsConsolidationPageInternal spaceId={spaceId} dispatchAction={dispatchAction} isPageHeaderVNextEnabled={isPageHeaderVNextEnabled} isDashboardVNextEnabled={isDashboardVNextEnabled}/>;
};
function hasReachedMinimumThresholdForHidingOnboardingOnDashboard(dataSourceState: RenderDashboardProps): boolean {
    return dataSourceState.cube ? Object.keys(dataSourceState.cube.projectIndex).length === 0 : false;
}
const filterSectionStyles = css({
    display: "flex",
    flexDirection: "column",
    gap: space["12"],
    marginBottom: space["4"],
});
const filtersContainerStyles = css({
    display: "flex",
    alignItems: "center",
    gap: space["24"],
});
const serverBasedFilterStyles = css({
    display: "flex",
    gap: space["12"],
});
const filtersAppliedTextStyles = css({
    display: "inline-flex",
    alignItems: "center",
    color: themeTokens.color.text.tertiary,
});
