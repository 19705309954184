import { Callout, Snackbar } from "@octopusdeploy/design-system-components";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type { PropsWithChildren } from "react";
import * as React from "react";
import { useState } from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { ProjectFormPageLayout } from "~/areas/projects/components/ProjectFormPageLayout";
import { AddProjectTemplateButton } from "~/areas/projects/components/Variables/TenantVariables/AddProjectTemplateButton";
import FeedbackExternalLink from "~/areas/projects/components/Variables/TenantVariables/FeedbackExternalLink";
import { IncludeLibraryVariableSetsButton } from "~/areas/projects/components/Variables/TenantVariables/IncludeLibraryVariableSetsButton";
import type { TemplatesWarnings } from "~/areas/projects/components/Variables/TenantVariables/ProjectTenantVariablesNavigationTabs";
import ProjectTenantVariablesNavigationTabs from "~/areas/projects/components/Variables/TenantVariables/ProjectTenantVariablesNavigationTabs";
import { useProjectTenantVariablesFeatureFlag } from "~/areas/projects/components/Variables/TenantVariables/useIsProjectTenantVariablesEnabled";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent/index";
import InternalLink from "~/components/Navigation/InternalLink/index";
import type { PageAction } from "~/components/PageActions/PageActions";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
interface ProjectTenantVariablesInlineLayoutProps {
    project: ProjectResource;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
    doBusyTask: DoBusyTask;
    saveAction: TenantVariablesPageSaveAction | undefined;
    warnings: TemplatesWarnings;
    page: TemplatesPage;
}
type TemplatesPage = "project" | "common";
const noChanges = { hasChanges: false };
export default function ProjectTenantVariablesInlineLayout({ children, project, busy, errors, doBusyTask, saveAction, warnings, page }: PropsWithChildren<ProjectTenantVariablesInlineLayoutProps>) {
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [snackbarText, setSnackbarText] = useState("");
    const [, setProjectTenantVariablesFeatureFlag] = useProjectTenantVariablesFeatureFlag();
    const dispatchAnalytics = useAnalyticActionDispatch();
    const openSnackbar = (text: string) => {
        setIsSnackbarOpen(true);
        setSnackbarText(text);
    };
    const createProjectTemplatesActions = (): PageAction[] => {
        const showOnboardingPage = !project.Templates || project.Templates.length === 0;
        if (showOnboardingPage) {
            return [];
        }
        const addProjectTemplate: PageAction = {
            type: "custom",
            key: "Add project template",
            hasPermissions: isAllowed({ permission: Permission.ProjectEdit, project: project.Id }),
            content: <AddProjectTemplateButton project={project} doBusyTask={doBusyTask} openSnackbar={openSnackbar}/>,
        };
        return [addProjectTemplate];
    };
    const createCommonTemplatesActions = (): PageAction[] => {
        const showOnboardingPage = !project.IncludedLibraryVariableSetIds || project.IncludedLibraryVariableSetIds.length === 0;
        if (showOnboardingPage) {
            return [];
        }
        const includeSetAction: PageAction = {
            type: "custom",
            key: "Include Library Variable Sets",
            hasPermissions: isAllowed({ permission: Permission.ProjectEdit, project: project.Id }),
            content: <IncludeLibraryVariableSetsButton project={project} doBusyTask={doBusyTask} openSnackbar={openSnackbar}/>,
        };
        return [includeSetAction];
    };
    const actions = buildPageActions(page, createProjectTemplatesActions, createCommonTemplatesActions);
    const handleOptOut = () => {
        setProjectTenantVariablesFeatureFlag(false);
        dispatchAnalytics("Opt Out of Project Tenant Variables", { resource: "Tenant", action: Action.OptOut });
    };
    const model = { hasChanges: !!(saveAction && !saveAction.disabled) };
    return (<ProjectFormPageLayout title={"Tenant Variables"} breadcrumbTitle={project?.Name} busy={busy} errors={errors} fullWidth={true} model={model} cleanModel={noChanges} savePermission={{
            permission: Permission.VariableEdit,
            project: project.Id,
            wildcard: true,
        }} saveText={"Variables updated"} onSaveClick={(isNavigationConfirmation: boolean) => saveAction?.onClick()} disableDirtyFormChecking={false} pageActions={actions} disableAnimations={true} disableKeyboardFormSubmission={true}>
            <Callout title={"Improving multiple variables editing experience"} type={"new-feature"}>
                This page is currently in an early-access phase and we'll be enhancing the experience over time. You can{" "}
                <InternalLink to={links.variableTemplatesPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug })} onClick={handleOptOut}>
                    switch back to the old design{" "}
                </InternalLink>
                at any time.
                <FeedbackExternalLink />
            </Callout>
            <ProjectTenantVariablesNavigationTabs warnings={warnings}></ProjectTenantVariablesNavigationTabs>
            {children}
            <Snackbar open={isSnackbarOpen} content={snackbarText} autoHideDuration={3500} onClose={() => setIsSnackbarOpen(false)} textAlign={"center"}/>
        </ProjectFormPageLayout>);
}
function buildPageActions(page: TemplatesPage, createForProjectTemplates: () => PageAction[], createForCommonTemplates: () => PageAction[]): PageAction[] {
    if (page === "project") {
        return createForProjectTemplates();
    }
    if (page === "common") {
        return createForCommonTemplates();
    }
    return [];
}
export interface TenantVariablesPageSaveAction {
    onClick: () => Promise<void>;
    disabled: boolean;
}
